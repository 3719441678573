import { Stripe, StripeCardElement } from '@stripe/stripe-js'
import { convertToTimeZone, formatToTimeZone, parseFromTimeZone } from '@zenchef/date-fns-timezone'
import { isColorLowContrast } from '@zenchef/ds-react'
import { CalendarEvent, google, ics, outlook } from 'calendar-link'
import cookie from 'cookie'
import {
  addDays,
  format,
  getDaysInMonth,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  subSeconds
} from 'date-fns'
import i18n from 'i18next'
import { action, IObservableArray, makeObservable, observable, reaction, toJS } from 'mobx'
import { computedFn, ITransformer } from 'mobx-utils'
import { NextPageContext } from 'next'
import getConfig from 'next/config'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import Router from 'next/router'
import { parseCookies } from 'nookies'
import { hotjar } from 'react-hotjar'
import { DefaultTheme } from 'styled-components'

import { ANALYTICS_EVENTS_NAMES, fireAnalyticEvent } from '@/lib/GoogleAnalytics'
import {
  Bookings,
  BookingVoucherCode,
  CreateBookingPayload,
  CreateBookingResponse,
  CustomerSheet,
  isTablebookerVoucherParam,
  PersonalFormData,
  ValidatedVoucherCode,
  ValidateVoucherResponse,
  VoucherCode,
  VoucherCodeApi,
  VoucherParam
} from '@/types/types'
import api from '@/utils/Api'
import createHmacString from '@/utils/createHmacString'
import { formatPrice } from '@/utils/CurrencyFormatter'
import errorMessageHandler, { ErrorOrResponseData } from '@/utils/errorMessageHandler'
import { computeDate } from '@/utils/formatDateTime'
import getBookingDuration from '@/utils/getBookingDuration'
import getTablebookerShopUrl from '@/utils/getTablebookerShopUrl'
import { groupByUnique } from '@/utils/helpers'
import { isInIframe, isInSdkIframe } from '@/utils/isInIframe'
import isNotNull from '@/utils/isNotNull'
import normalizeLang from '@/utils/normalizeLang'
import { landingPage } from '@/utils/publicUrls'
import defaultTheme, { setDerivedThemeTokens } from '@/utils/theme'
import { translateField } from '@/utils/translationService'
const {
  publicRuntimeConfig: {
    BOOKINGS_DOMAIN,
    HOTJAR_SITE_ID,
    HOTJAR_SITE_VERSION,
    STRIPE_PUBLISHABLE_KEY,
    ADYEN_ENV,
    ADYEN_PUBLIC_KEY,
    SECRET_KEY,
    STRIPE_TEST_PUBLISHABLE_KEY,
    ADYEN_TEST_ENV,
    ADYEN_TEST_PUBLIC_KEY
  }
} = getConfig()

interface Query extends NextParsedUrlQuery {
  fullscreen?: string
  withCloseButton?: string
  white_logo?: string
  day?: string
  rid?: string
  lang?: string
  srid?: string
  pax?: string
  mini?: string
  pid?: string | string[]
  icid?: string | string[]
  prescriber_id?: string
  type?: string
  hidelang?: string
  colorMode?: string
  primaryColor?: string
  pixelid?: string
  'source-rid'?: string
  waiting_list?: string
  room_id?: string
  firstname?: string
  lastname?: string
  'force-prepayment'?: string
  token?: string
  cmp?: string
  redirectResult?: string
  showCollapsed?: string
  iframePosition?: 'left' | 'center' | 'right'
}
const IFRAME_POSITIONS = ['left', 'center', 'right']
const DEFAULT_IFRAME_POSITION = 'right'

interface Group {
  id: number
}

interface Quotation {
  id: number | null
  amount: number | null
  currency: string | null
  clientSecret?: string
}

interface GenericDay<T extends Bookings.Shift | Bookings.SummaryShift> {
  date: string
  shifts: T[]
  isOpen?: 'closed'
}

type Day = GenericDay<Bookings.Shift>

type SummaryDay = GenericDay<Bookings.SummaryShift>

interface Month {
  days: SummaryDay[]
  key: string
  partial?: boolean
}

interface FormData extends Omit<PersonalFormData, 'lang'> {
  eula_accepted: boolean
  tmp_phone_valid: string
  tmp_phone: string
  printedPhone: string
  phone_number: string
  comment: string
  custom_field: Record<string, string>
  save_info: boolean
  type_event: string
  budget: string
  zip: string
  moment: 'day' | 'evening' | ''
  type_client: 'private' | 'professional' | ''
  event_type: string
  consent_loosing_confirmation: boolean
}

interface Order {
  amount: string | null
  id: number | null
  number: number | null
  currency: string | null
  customersheet: CustomerSheet | null
  shop_order_products: { quantity: number; product: Bookings.Product }[]
  clientSecret: string | null
}

interface Notification {
  name: string
  sms?: boolean
  mail?: boolean
  eco?: boolean
}

interface Publisher {
  publisher: string
}

interface VoucherCodeErrorContext {
  valid_until: string | null
}

interface VoucherCodes {
  validatedVoucherCodes: ValidatedVoucherCode[]
  bookingVoucherCodes: BookingVoucherCode[]
  error?: string
  errorContext?: VoucherCodeErrorContext
}

type CalendarType = 'GOOGLE' | 'OUTLOOK' | 'ICS'

interface UpdateBookingData {
  day: string
  time?: string
  nb_guests: number
  offers: Bookings.WishOffer[]
  wish: { booking_room_id: number | null }
  comment?: string
}

interface Actions {
  isRequired: (key: string) => boolean
  isDisplayed: (key: string) => boolean
  createQuotation: () => void
  createBooking: (options?: { withPrepayment?: boolean; withEB?: boolean }) => void
  updateBooking: () => void
  getDailyAvailabilities: (dateString: string) => Promise<void | Day>
  createPrivatisation: () => Promise<boolean>
  createSuggestedRestaurantStores: () => void
  loadSuggestedRestaurantsData: () => Promise<void>
  getAvailabilitiesSummary: (month: number | string, year: number | string) => Promise<void>
  getCommentSpecific: (day: string) => Promise<void>
  getWidgetParams: () => Promise<void>
  setAuthToken: () => void
  setThemeOverrides: () => void
  isShiftDayWaitlistAvailableForCurrentPax: (day: Day) => void
  getRestaurantInfo: () => Promise<{ data: Bookings.RestaurantInfo }>
  patchOptin: (
    attr: Bookings.Optin['type'],
    value: Bookings.Optin['value'],
    customerSheetId?: number
  ) => Promise<unknown>
  createOrder: () => Promise<unknown>
  getProducts: () => Promise<unknown>
  getCalendarLink: (type: CalendarType) => string
  getMarketingLink: (origin: string) => string
  selectWishDay: (day: string) => Promise<void>
  prefillFormData: (ctx: NextPageContext) => void
  checkoutOrder: () => Promise<unknown>
  authorizeStripePayment: (stripe: Stripe, cardElement: StripeCardElement) => Promise<unknown>
  postBookingCharge: (data: unknown) => void
  authorizeAdyenPayment: (payload: { adyenData: unknown; adyenRedirectResult: unknown }) => Promise<unknown>
  getAdyenPaymentMethods: () => unknown
  getBookingByChargeToken: (token: string) => unknown
  getBookingBySetupIntentId: (token: string) => unknown
  getBookingByUuid: (uuid: string) => Promise<Bookings.BookingDetails>
  setIsCollapsed: (value: boolean) => void
  resetIsCollapsed: () => void
  setSdkLocationHref: (sdkLocationHref: string) => void
  addOffer: (offer_id: number) => void
  removeOffer: (offer_id: number) => void
  loadBookingWithUuid: (uuid: string) => Promise<void>
  resetOfferSelectionHasBeenCleared: () => void
}

interface State {
  isDisabled: boolean
  shouldDisplayShopVoucher: boolean
  mini: boolean
  query: Query
  sdkLocationHref?: string
  is_white_label?: boolean
  logo?: string
  theme: DefaultTheme
  isLightColorMode?: boolean
  isShopWidget?: boolean
  newPathname: null | string
  paymentMethods: null | string
  name?: string
  city?: string
  wish: Bookings.Wish & { offers: IObservableArray<Bookings.WishOffer> }
  isInUpdateFlow: boolean
  isModificationForbidden: boolean
  previousWish: Bookings.PreviousWish
  sdk: boolean
  isFullscreen: boolean
  isCollapsed: boolean
  showCollapsed: boolean
  overridePlaceholder: boolean
  language: string
  restaurantTimezone?: string
  restaurantCountry?: string
  selectedShift?: Bookings.Shift | null
  selectedRoomName?: string
  selectedOffers: Bookings.SelectedOffer[]
  selectedDay?: Required<Pick<Day, 'shifts'>> | null
  googleCalUrl: () => string
  currentDay: Day
  groups: Group[]
  publishers?: Publisher[]
  shouldPrepayOffers: boolean
  shouldPrecharge: boolean
  shouldPrepayShift: boolean
  shouldPrepay: boolean
  shouldPrechargeOrPrepayShift: boolean
  formData: FormData
  mandatoryFields: Record<string, unknown>
  customFields: Bookings.CustomField[]
  formValidationError: Record<string, boolean>
  apiValidationError: Record<string, boolean>
  offerUnitPriceFormatted: (offer: Bookings.SelectedOffer) => string
  restaurantId?: string
  specificErrorKey?: string | null
  error?: string[] | string | null
  errors?: string[]
  isLoading: boolean
  ebType: string
  has_adyen_for_prepayment?: boolean
  hasConnectedVouchers: boolean
  createdCustomerSheet: CustomerSheet
  selectedSlot?: Bookings.Slot | null
  computeOffersFromShiftAndSlot: (shift: Bookings.Shift, slot: Bookings.Slot) => Bookings.SelectedOffer[]
  computeOfferFromShiftAndSlot: (offerId, shift: Bookings.Shift, slot: Bookings.Slot) => Bookings.SelectedOffer
  getFullOffer: (offer: Bookings.WishOffer) => Bookings.SelectedOffer
  getFullOfferOrNull: (offer: Bookings.WishOffer) => Bookings.SelectedOffer | null
  totalCountLimitedToPaxWishOffers: number
  availableOffersFromSelectedSlot: Bookings.SelectedOffer[]
  availableOffersFromWish: Bookings.SelectedOffer[]
  selectedOffersFormattedPrice: string
  selectedOffersPrice: number
  selectedOffersWithPrepaymentPrice: number
  shouldShowOffersTotalAmount: boolean
  shiftPrepaymentPrice: number
  formattedPrepaymentPrice: string
  prepaymentPrice: number
  openDays: string[]
  isOfferRequiredInShift: (shift: Bookings.Shift) => boolean
  isOfferRequiredInShiftForPax: (shift: Bookings.Shift, pax: number) => boolean
  waiting_list: unknown
  stripePublishableKey: string
  customFieldsPrivatisation: Bookings.CustomField[]
  isSummaryDateAvailableForCurrentPax: (date: string) => boolean
  isSummaryDateFull: (date: string) => boolean
  isSummaryDateNotOpenYet: (date: string) => boolean
  isSummaryDateNotOpenAnymore: (date: string) => boolean
  isSummaryDateFullPax: (date: string) => boolean
  isSummaryDateClosed: (date: string) => boolean
  isSummaryDateWaitlistAvailableForCurrentPax: (date: string) => boolean
  isDateInThePast: (date: string) => boolean
  isDateToday: (date: string) => boolean
  isPrivatisation: boolean
  partner_id?: string
  shouldLoadSuggestedRestaurants: boolean
  isCurrentDayAvailableForCurrentPax: boolean
  isCurrentDayAvailableInSuggestedRestaurants: boolean
  isCurrentDayWaitlistAvailableForCurrentPax: boolean
  isShiftSlotsAvailableInSuggestedRestaurants: ITransformer<unknown, boolean>
  suggestedAppStoresInitialized: boolean
  isCurrentDayFinished: boolean
  isCurrentDayOpen: boolean
  currentMonth?: Month
  currentMonthKey: string
  shift_limit?: {
    min: string
    max: string
  }
  countOpenShifts: number
  getAllAvailableSlots: (shift: Bookings.Shift) => Bookings.Slot[]
  isRoomMandatory: boolean
  restaurantSpecificCommentsByDay: Record<string, TranslatableValues>
  restaurantSpecificComment: TranslatableValues | null
  currentDayBookableRooms: Bookings.Room[]
  currentSlotAvailableRooms: Bookings.Room[]
  isRoomAvailableInSlot: (roomId: number, slot: Bookings.Slot) => boolean | undefined
  isRoomBookableInShift: (room: Bookings.Room, shift: Bookings.Shift) => boolean
  isShiftPassedOrClosed: (shift: Bookings.Shift) => boolean
  isSlotAvailable: (slot: Bookings.Slot, shift: Bookings.Shift) => boolean
  isSlotAvailableForWaitlist: (slot: Bookings.Slot, shift: Bookings.Shift) => boolean
  isCurrentDayAvailableInSuggestedRestaurantsBetween: (open: string, close: string) => boolean
  hasRoomSelection?: boolean
  hasStockTable?: boolean
  isShiftSlotAndWishCompatibleWithOffers: (
    shift: Bookings.Shift,
    slot: Bookings.Slot,
    type?: 'booking' | 'waitlist'
  ) => boolean
  isOffersSetValidWithRoomId: (offers: Bookings.SelectedOffer[], roomId: number) => boolean
  isOffersSetValidWithRooms: (offers: Bookings.SelectedOffer[], rooms: Bookings.Room[]) => boolean
  isOffersSetValidWithLimitedToPax: (offers: Bookings.SelectedOffer[], pax: number) => boolean
  isOffersSetValidWithPax: (offers: Bookings.SelectedOffer[], pax: number) => boolean
  isOffersSelectionAvailableWithPax: (offers: Bookings.SelectedOffer[], pax: number) => boolean
  getRoomsCompatibleWithPaxAndSelectedRoom: (
    shift: Bookings.Shift,
    slot: Bookings.Slot,
    pax: number,
    roomId: number | null,
    type?: 'booking' | 'waitlist'
  ) => Bookings.Room[]
  isOffersSetAvailableWithPax: (
    offers: Bookings.SelectedOffer[],
    pax: number,
    isOfferRequiredInShift: boolean
  ) => boolean
  isOffersSetValidWithShiftSlotPaxAndRoom: (
    offers: Bookings.SelectedOffer[],
    shift: Bookings.Shift,
    slot: Bookings.Slot,
    pax: number,
    roomId: number | null,
    type?: 'booking' | 'waitlist'
  ) => boolean
  isShiftSlotPaxAndRoomCompatibleWithAvailableOffers: (
    shift: Bookings.Shift,
    slot: Bookings.Slot,
    pax: number,
    roomId: number | null,
    type?: 'booking' | 'waitlist'
  ) => boolean
  availableOffersFromSlot: (slot: Bookings.Slot) => Bookings.SelectedOffer[]
  initializedSSR?: boolean
  language_availabilities: string[]
  suggestedRestaurantIds: number[]
  sourceRestaurantId?: number | null
  incomingCallerId?: string
  prescriber_id?: string
  type: string | null
  hideLang?: boolean
  colorMode?: string
  today: Date
  overrideComment?: boolean
  overridePrivateComment?: boolean
  initialized?: boolean
  isTestRestaurant?: boolean
  // eslint-disable-next-line no-use-before-define
  suggestedAppStores: Record<number, AppStore>
  widgetParameters: { primaryColor: string }
  nowLocal: Date
  nowUTC: Date
  chargeTimeCancelReference: string | null
  rooms: Bookings.Room[]
  roomsById: Record<Bookings.Room['id'], Bookings.Room>
  hasChargeAccount?: boolean
  imprint_param?: boolean
  hasPrecharge?: boolean
  prepayment_param?: string
  hasPrepayment: boolean
  hasShiftPrepayment: boolean
  months: Month[]
  days: SummaryDay[]
  dailyAvailabilities: Record<string, Day>
  daysDictionary: Record<string, SummaryDay>
  getAvailableSlots: (shift: Bookings.Shift) => Bookings.Slot[]
  currentHour: number
  currentMinutes: number
  isCurrentDayAvailableBetween: (open: string, close: string) => boolean
  getDayFromDate: (date: string) => SummaryDay
  closedBookingsAfter: number | null
  closedBookingsBefore: number | null
  notificationSubscriptions?: Notification[]
  getAvailableWaitlistSlots: (shift: Bookings.Shift) => Bookings.Slot[]
  getStock: (offer: Bookings.SlotOffer) => number
  wishOffersLimitedToPax: Bookings.WishOffer[]
  formattedPrice: (price: number, currency?: string) => string
  computeProductPrice: (product: Bookings.Product, quantity: number) => number
  productUnitPriceFormatted: (product: Bookings.Product) => string
  productPriceFormatted: (product: Bookings.Product, quantity: number) => string
  selectedProducts: IObservableArray<Bookings.Product>
  order: Order
  computeOfferPrice: (offer: Bookings.SelectedOffer) => number
  currency: string
  restaurantLanguage?: string
  bookingDuration: number | null
  timestamp?: number
  authToken?: string
  shiftsSlotsPageIndexes?: Record<number, number>
  quotation?: Quotation
  quotationAmountFormatted?: string
  paymentIntentId?: number | null
  paymentMethodId?: number | null
  tagManager?: unknown
  websiteUrl?: string
  address?: string
  address2?: string
  zip?: string
  acl: IObservableArray<string>
  restaurantComment: TranslatableValues | null
  phone: string | null
  sha256: string
  adyenEnv: string
  adyenPublicKey: string
  analytics?: string | null
  analytics_tag?: unknown
  facebookPixel?: unknown
  cookieString?: string
  pendingBookingId: number | null
  bookingUuid: string | null
  stripe_client_secret?: string
  currentFetchingMonth?: string
  setupIntentId: string | null
  products: IObservableArray<Bookings.Product>
  icid?: string
  postBookingBody?: string
  pendingBooking: unknown
  booking?: Bookings.BookingDetails
  relatedBookingId?: number
  orderAmount?: string
  cartPrice?: string
  calculatedChargeTimeCancel: Date | null
  nearSlots: Bookings.Slot[]
  reservationAutoConf: null | unknown
  printedPhone: null | string
  custom_field: unknown
  optins: Bookings.Optin[]
  selectedCalendarLink: null | string
  countSelectedOffers: number
  offerSelectionHasBeenCleared: boolean
  bookingExpiresDate?: Date | null
  paymentExpiresDate?: Date | null
  voucherParam?: VoucherParam
  voucherCodes: VoucherCodes
}

interface IAppStore extends Actions {
  state: State
}

class AppStore implements IAppStore {
  @observable state: State

  constructor(query?: Query | null) {
    this.createState()
    this.state.wish.day = formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: 'UTC' })

    if (query) {
      this.state.query = query

      this.state.restaurantId =
        query.rid && !isNaN(parseInt(query.rid.trim())) ? `${parseInt(query.rid.trim())}` : undefined

      if (query.lang) {
        // MODIF FOR NEW LANGUAGES
        if (this.state.language_availabilities.length) {
          if (this.state.language_availabilities.includes(query.lang)) {
            this.state.language = query.lang
          } else {
            this.state.language = 'en'
          }
        } else {
          // FALLBACK IN CASE WE DON'T HAVE ANY LANGUAGES AVAILABLE
          this.state.language = normalizeLang(query.lang)
        }
      }
      if (query.srid) {
        this.state.suggestedRestaurantIds = query.srid
          .split(',')
          .map((restaurantId) => Number(restaurantId.trim()))
          .filter((rid) => !isNaN(rid))
      }
      if (query['source-rid']) {
        const sourceRestaurantId = Number(query['source-rid'].trim())
        if (!isNaN(sourceRestaurantId)) {
          this.state.sourceRestaurantId = sourceRestaurantId
        }
      }
      if (query.pax) {
        this.state.wish.pax = parseInt(query.pax) || 2
      }
      if (query.day) {
        this.state.wish.day = query.day
      }
      if (query.mini) {
        this.state.mini = true
      }
      if (query.pid) {
        this.state.partner_id = Array.isArray(query.pid) ? query.pid[0] : query.pid
      }
      if (query.icid) {
        this.state.incomingCallerId = Array.isArray(query.icid) ? query.icid[0] : query.icid
      }
      if (query.prescriber_id) {
        this.state.prescriber_id = query.prescriber_id
      }
      if (query.type) {
        this.state.type = query.type
      }
      if (query.hidelang) {
        this.state.hideLang = true
      }
      if (query.colorMode) {
        this.state.colorMode = query.colorMode
      }
      if (this.state.sdk && this.state.showCollapsed) {
        this.state.isCollapsed = true
      }
      if (query.iframePosition && !IFRAME_POSITIONS.includes(query.iframePosition)) {
        this.state.query.iframePosition = DEFAULT_IFRAME_POSITION
      }
      if (query.buuid && !Array.isArray(query.buuid)) {
        this.state.bookingUuid = query.buuid
        this.state.isInUpdateFlow = true
      }
      if (query.fullscreen) {
        this.state.isFullscreen = true
      }
    }
    makeObservable(this)
  }

  fetchAvailabilitiesAndSpecificComment = (day: string) => {
    return Promise.all([this.getDailyAvailabilities(day), this.getCommentSpecific(day)])
  }

  initServerSide = async (ctx) => {
    if (this.state.initializedSSR) {
      return Promise.resolve(this)
    }

    if (this.state.restaurantId) {
      try {
        const { bookingUuid, isInUpdateFlow } = this.state
        const { query } = ctx

        if (ctx.pathname === '/results' && bookingUuid && isInUpdateFlow) {
          await this.loadBookingWithUuid(bookingUuid)
        }

        const { day: initialDay } = this.state.wish

        await Promise.all([
          this.getWidgetParams(),
          this.setAuthToken(),
          ...(!['/cookie_policy', '/gtc', '/privacy_policy', '/privacy_policy_short', '/privatisation'].includes(
            ctx.pathname
          )
            ? [this.fetchAvailabilitiesAndSpecificComment(initialDay)]
            : []),
          ...(query && query['force-prepayment'] && query.token ? [this.getBookingByChargeToken(query.token)] : []),
          // In case we are redirected from a stripe imprint
          ...(query && query.setup_intent && query.setup_intent_client_secret
            ? [this.getBookingBySetupIntentId(query.setup_intent)]
            : [])
        ])

        const localTodayString = formatToTimeZone(new Date(), 'YYYY-MM-DD', {
          timeZone: this.state.restaurantTimezone ?? 'UTC'
        })

        const isWishDayTodayInLocalTime = initialDay === localTodayString
        if (
          !['/cookie_policy', '/gtc', '/privacy_policy', '/privacy_policy_short', '/privatisation'].includes(
            ctx.pathname
          ) &&
          !this.state.query?.day &&
          !this.state.isInUpdateFlow
        ) {
          // Reload today availabilities if today is not the same as UTC in local time
          if (!isWishDayTodayInLocalTime) {
            this.state.wish.day = localTodayString
            await this.fetchAvailabilitiesAndSpecificComment(this.state.wish.day)
          }

          // Load tomorrow availabilities if the day is finished
          if (this.state.isCurrentDayFinished) {
            const tomorrow = formatToTimeZone(addDays(new Date(), 1), 'YYYY-MM-DD', {
              timeZone: this.state.restaurantTimezone ?? 'UTC'
            })
            this.state.wish.day = tomorrow
            await this.fetchAvailabilitiesAndSpecificComment(this.state.wish.day)

            // if there is still no availability tomorrow, go back to today
            // no need to fetch again since it will already be loaded
            if (!this.state.isCurrentDayOpen) {
              this.state.wish.day = localTodayString
            }
          }
        }
      } catch (e) {
        console.log(e)
        return Promise.reject(e)
      }

      this.prefillFormData(ctx)
      this.setThemeOverrides()

      if (this.state.restaurantId === '343886') {
        // override comment
        this.state.overrideComment = true
      }

      if (this.state.restaurantId === '348663') {
        // placeholder comment_allergies instead of message
        this.state.overridePlaceholder = true
      }

      if (this.state.restaurantId === '80791') {
        // override comment on privatisation page
        this.state.overridePrivateComment = true
      }

      const { voucherParam } = this.state
      if (ctx.pathname === '/shop' && isTablebookerVoucherParam(voucherParam)) {
        const { language, theme } = this.state
        const [, primaryColor] = theme.colors.primary?.split('#')
        const { res } = ctx

        const shopUrl = getTablebookerShopUrl({
          tablebookerShopId: voucherParam.tablebooker_shop_id,
          primaryColor,
          language
        })

        res.setHeader('Location', shopUrl)
        res.statusCode = 302
        res.end()

        return
      }
    }

    this.state.initializedSSR = true
    return Promise.resolve(this)
  }

  async init() {
    if (this.state.initialized) {
      return Promise.resolve()
    }

    if (!isInIframe()) {
      this.state.isFullscreen = false
    }

    if (
      this.state.restaurantId &&
      !['/cookie_policy', '/gtc', '/privacy_policy', '/privacy_policy_short', '/privatisation'].includes(
        window.location.pathname
      )
    ) {
      const [year, month] = this.state.currentMonthKey.split('-')
      this.getAvailabilitiesSummary(month, year)
    }

    if (
      this.state.restaurantId &&
      !['/cookie_policy', '/gtc', '/privacy_policy', '/privacy_policy_short'].includes(window.location.pathname)
    ) {
      this.state.initialized = true
    }
  }

  loadBookingWithUuid = async (bookingUuid: string) => {
    const booking = await this.getBookingByUuid(bookingUuid)
    const { booking_offers, shift_date, day, time, nb_guests, lang } = booking
    const wishDay = shift_date ?? day

    const previousWish: Bookings.PreviousWish = {
      day: wishDay,
      slot: time,
      pax: nb_guests,
      lang,
      waiting_list: false,
      room_id: booking.wish?.booking_room_id ?? null,
      offers: booking_offers.map(({ offer_id, count }) => ({ offer_id, count }))
    }

    Object.assign(this.state.previousWish, { ...previousWish })
    Object.assign(this.state.wish, { ...previousWish })
    const {
      comment,
      customersheet: { optins }
    } = booking
    Object.assign(this.state.formData, {
      comment: comment ?? '',
      optins
    })
    try {
      await this.getDailyAvailabilities(wishDay)
    } catch (e) {
      this.state.dailyAvailabilities[wishDay] = { date: wishDay, shifts: [] }
    }
  }

  createSuggestedRestaurantStores = async () => {
    const { day, pax } = this.state.wish
    const hotjarId = HOTJAR_SITE_ID
    const hotjarVersion = HOTJAR_SITE_VERSION

    if (
      hotjarId !== undefined &&
      !this.state.isTestRestaurant &&
      this.state.suggestedRestaurantIds.length > 0 &&
      !hotjar.initialized()
    ) {
      hotjar.initialize(hotjarId, hotjarVersion)
    }

    if (this.state.suggestedAppStoresInitialized) {
      return
    }

    this.state.suggestedAppStores = this.state.suggestedRestaurantIds.reduce((dict, rid) => {
      dict[rid] = new AppStore({
        day,
        pax: String(pax),
        rid: String(rid),
        'source-rid': String(this.state.restaurantId)
      })
      return dict
    }, {})

    reaction(
      () => {
        return JSON.stringify(this.state.wish)
      },
      () => {
        const { day, pax } = this.state.wish
        Object.values(this.state.suggestedAppStores).forEach((suggestedAppStore) => {
          suggestedAppStore.state.wish.day = day
          suggestedAppStore.state.wish.pax = pax
        })
      }
    )

    try {
      await Promise.all(
        this.state.suggestedRestaurantIds.map(async (restaurantId) => {
          const appStore = this.state.suggestedAppStores[restaurantId]
          try {
            await Promise.all([appStore.getWidgetParams(), appStore.setAuthToken()])
          } catch (e) {
            console.error(e)
            console.log('there was an error loading suggested restaurant')
          }
        })
      )
      this.state.suggestedAppStoresInitialized = true
    } catch (e) {
      console.log(e)
      return Promise.reject(e)
    }
  }

  loadSuggestedRestaurantsData: Actions['loadSuggestedRestaurantsData'] = async () => {
    const { query, wish, currentMonthKey } = this.state
    const { day, pax } = wish
    const [year, month] = currentMonthKey.split('-')

    this.state.suggestedRestaurantIds.forEach((restaurantId) => {
      const appStore = this.state.suggestedAppStores[restaurantId]
      appStore.state.wish.day = day
      appStore.state.wish.pax = pax
      appStore.state.query.fullscreen = query.fullscreen
    })

    try {
      await Promise.all(
        this.state.suggestedRestaurantIds.map(async (restaurantId) => {
          const appStore = this.state.suggestedAppStores[restaurantId]
          try {
            await Promise.all([
              appStore.fetchAvailabilitiesAndSpecificComment(day),
              appStore.getAvailabilitiesSummary(month, year)
            ])
            appStore.setThemeOverrides()
            appStore.state.initialized = true
          } catch (e) {
            console.log('there was an error loading suggested restaurant')
          }
        })
      )
    } catch (e) {
      console.log(e)
      return Promise.reject(e)
    }
  }

  @action
  setThemeOverrides: Actions['setThemeOverrides'] = () => {
    const primaryColor = this.state.query.primaryColor
      ? this.state.query.primaryColor
      : this.state.widgetParameters.primaryColor
    if (!primaryColor) {
      return
    }

    if (this.state.sdk) {
      // In case of SDK we don't want the responsive breakpoints to be applied
      this.state.theme.breakpoints = [400, 1200, 1400]
    }

    this.state.theme.colors.primary = primaryColor.match(/^(?:[0-9a-f]{3}){1,2}$/i) ? `#${primaryColor}` : primaryColor
    if (!this.state.query.colorMode) {
      this.state.colorMode = isColorLowContrast(this.state.theme.colors.primary) ? 'light' : 'dark'
    }
    setDerivedThemeTokens(this.state.theme)
  }

  createState = () => {
    const appStore = this
    this.state = observable<State>(
      {
        isDisabled: true,
        shouldDisplayShopVoucher: false,
        theme: defaultTheme,
        pendingBookingId: null,
        bookingUuid: null,
        isShopWidget: false,
        newPathname: null,
        analytics: null,
        analytics_tag: null,
        formValidationError: {},
        apiValidationError: {},
        name: '',
        sha256: '',
        widgetParameters: {
          primaryColor: ''
        },
        get sdk() {
          return appStore.state.query.sdk === '1'
        },
        isFullscreen: false,
        isCollapsed: false,
        get showCollapsed() {
          return appStore.state.sdk && appStore.state.query.showCollapsed === '1'
        },
        get isPrivatisation() {
          if (appStore.state.query && 'privatisation' in appStore.state.query) return true
          return false
        },
        isInUpdateFlow: false,
        get isModificationForbidden() {
          return appStore.state.isInUpdateFlow && !appStore.state.booking?.is_modifiable_by_enduser
        },
        mini: false,
        hideLang: false,
        overridePlaceholder: false,
        overridePrivateComment: false,
        overrideComment: false,
        initialized: false,
        initializedSSR: false,
        language: 'en',
        language_availabilities: [],
        restaurantCountry: undefined,
        restaurantLanguage: undefined,
        query: {},
        restaurantId: undefined,
        groups: [],
        publishers: [],
        hasConnectedVouchers: false,
        paymentMethods: null,
        rooms: [],
        wish: {
          pax: 2,
          day: formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: 'UTC' }),
          slot: undefined,
          offers: observable<Bookings.WishOffer>([]),
          waiting_list: false,
          room_id: null
        },
        previousWish: {},
        suggestedRestaurantIds: [],
        suggestedAppStores: {},
        suggestedAppStoresInitialized: false,
        sourceRestaurantId: null,
        colorMode: 'dark',
        get isLightColorMode() {
          return appStore.state.colorMode === 'light'
        },
        today: parseFromTimeZone(new Date().toString(), { timeZone: 'UTC' }),
        nowLocal: new Date(),
        get nowUTC() {
          return convertToTimeZone(appStore.state.nowLocal, { timeZone: 'UTC' })
        },
        get currentHour() {
          return getHours(appStore.state.nowUTC)
        },
        get currentMinutes() {
          return getMinutes(appStore.state.nowUTC)
        },
        get selectedDay() {
          const day = appStore.state.currentDay
          if (day && day.shifts && day.shifts.length) {
            const object = observable({
              shifts: day.shifts.map(({ shift_slots, ...restShift }) => ({
                shift_slots: shift_slots.filter((slot) => !slot.closed),
                ...restShift
              }))
            })
            return object
          } else {
            return null
          }
        },
        get selectedShift() {
          if (!appStore.state.selectedDay) return null
          return appStore.state.selectedDay.shifts.find((shift) => {
            return shift.shift_slots.some((shiftSlot) => shiftSlot.name === appStore.state.wish.slot)
          })
        },
        get chargeTimeCancelReference() {
          if (!appStore.state.selectedShift) return null
          if (appStore.state.selectedShift?.cancelation_param?.enduser_cancelable_reference === 'shift') {
            return `${appStore.state.wish.day} ${appStore.state.selectedShift.open}`
          }
          return `${appStore.state.wish.day} ${appStore.state.wish.slot}`
        },
        get calculatedChargeTimeCancel() {
          if (
            !appStore.state.selectedShift ||
            !appStore.state.selectedShift.cancelation_param ||
            appStore.state.chargeTimeCancelReference === null ||
            !appStore.state.restaurantTimezone
          )
            return null
          return subSeconds(
            parseFromTimeZone(appStore.state.chargeTimeCancelReference, {
              timeZone: appStore.state.restaurantTimezone
            }),
            appStore.state.selectedShift.cancelation_param.enduser_cancelable_before
          )
        },
        get selectedSlot() {
          if (!appStore.state.selectedShift) return null
          const shiftSlot = appStore.state.selectedShift.shift_slots.find(
            (shiftSlot) => shiftSlot.name === appStore.state.wish.slot
          )
          return shiftSlot
        },
        computeOffersFromShiftAndSlot(shift, slot) {
          return (slot.offers ?? []).map((offer) => {
            return appStore.state.computeOfferFromShiftAndSlot(offer.id, shift, slot)
          })
        },
        computeOfferFromShiftAndSlot(offerId: number, shift, slot): Bookings.SelectedOffer {
          const currentOffer = (shift.offers ?? []).find(({ id }) => id === offerId)
          const currentOfferFromSlot = (slot.offers ?? []).find(({ id }) => id === offerId)
          if (!currentOffer || !currentOfferFromSlot) {
            throw new Error('invalid usage of computeOfferFromShiftAndSlot function')
          }

          return {
            ...currentOffer,
            ...currentOfferFromSlot,
            id: offerId
          }
        },
        getFullOffer: computedFn((offer) => {
          if (!appStore.state.selectedShift || !appStore.state.selectedSlot) {
            throw new Error('computing selected offer before shift and slot selection')
          }
          const offerFromShiftAndSlot = appStore.state.computeOfferFromShiftAndSlot(
            offer.offer_id,
            appStore.state.selectedShift,
            appStore.state.selectedSlot
          )
          return {
            ...offerFromShiftAndSlot,
            ...offer
          }
        }),
        getFullOfferOrNull: computedFn((offer) => {
          try {
            return appStore.state.getFullOffer(offer)
          } catch (e) {
            return null
          }
        }),
        get selectedOffers() {
          return appStore.state.wish.offers
            .map((offer) => {
              return appStore.state.getFullOfferOrNull(offer)
            })
            .filter(isNotNull)
        },
        get countSelectedOffers() {
          return appStore.state.selectedOffers.reduce((sum, o) => sum + o.count, 0)
        },
        get selectedRoomName() {
          const room = appStore.state.currentDayBookableRooms.find((r) => r.id === appStore.state.wish.room_id)

          return translateField(this.language, this.restaurantLanguage, room?.name_translations)
        },
        get currentDayBookableRooms() {
          return appStore.state.rooms.filter((room: Bookings.Room) => {
            return appStore.state.currentDay?.shifts.some((shift) => appStore.state.isRoomBookableInShift(room, shift))
          })
        },
        isRoomBookableInShift: computedFn((room, shift) => (shift.bookable_rooms ?? []).includes(room.id)),
        get currentSlotAvailableRooms() {
          return appStore.state.rooms.filter((room: Bookings.Room) => {
            if (!appStore.state.selectedSlot) {
              throw new Error('calling currentSlotAvailableRooms without selectedSlot')
            }
            return appStore.state.isRoomAvailableInSlot(room.id, appStore.state.selectedSlot)
          })
        },
        isRoomAvailableInSlot: computedFn((room_id: number, slot: Bookings.Slot) => {
          return slot.available_rooms !== undefined && appStore.state.wish.pax in slot.available_rooms
            ? slot.available_rooms[appStore.state.wish.pax].includes(room_id)
            : false
        }),
        hasStockTable: false,
        hasRoomSelection: false,
        isRoomMandatory: false,
        restaurantSpecificCommentsByDay: {},
        get restaurantSpecificComment() {
          const { restaurantSpecificCommentsByDay, wish } = appStore.state
          return restaurantSpecificCommentsByDay[wish.day] ?? null
        },
        isTestRestaurant: false,
        nearSlots: [],
        restaurantComment: null,
        error: '',
        mandatoryFields: {},
        customFields: [],
        customFieldsPrivatisation: [],
        reservationAutoConf: null,
        phone: null,
        printedPhone: null,
        isLoading: true,
        tagManager: null,
        facebookPixel: null,
        type: null,
        acl: observable<string>([]),
        products: observable<Bookings.Product>([]),
        selectedProducts: observable<Bookings.Product>([]),
        createdCustomerSheet: {
          id: null,
          optins: null,
          relatedBookingId: null
        },
        currency: '',
        quotation: {
          id: null,
          amount: null,
          currency: null
        },
        order: {
          id: null,
          number: null,
          amount: null,
          currency: null,
          customersheet: null,
          shop_order_products: [],
          clientSecret: null
        },
        setupIntentId: null,
        stripePublishableKey: STRIPE_PUBLISHABLE_KEY,
        adyenEnv: ADYEN_ENV,
        adyenPublicKey: ADYEN_PUBLIC_KEY,
        get hasPrecharge() {
          return !!(
            (appStore.state.hasChargeAccount || appStore.state.imprint_param) &&
            appStore.state?.selectedShift?.charge_param &&
            appStore.state?.selectedShift?.charge_param?.is_web_booking_askable
          )
        },
        get shouldPrecharge() {
          if (!appStore.state.selectedShift) {
            return false
          }
          return (
            appStore.state.selectedShift.charge_param !== undefined &&
            !appStore.state.shouldPrepay &&
            !appStore.state.wish.waiting_list &&
            !!(
              appStore.state.hasPrecharge &&
              appStore.state.selectedShift.charge_param.min_guests &&
              appStore.state.wish.pax >= appStore.state.selectedShift.charge_param.min_guests
            )
          )
        },
        get hasPrepayment() {
          return !!(appStore.state.hasChargeAccount || appStore.state.prepayment_param)
        },
        get hasShiftPrepayment() {
          return !!(
            appStore.state.hasPrepayment && appStore.state.selectedShift?.prepayment_param?.is_web_booking_askable
          )
        },
        get shouldPrepayShift() {
          if (!appStore.state.selectedShift) {
            return false
          }
          return !!(
            appStore.state.hasShiftPrepayment &&
            !appStore.state.wish.waiting_list &&
            appStore.state.selectedShift.prepayment_param?.charge_per_guests &&
            appStore.state.selectedShift.prepayment_param?.min_guests &&
            appStore.state.wish.pax >= appStore.state.selectedShift.prepayment_param.min_guests
          )
        },
        get shouldPrepayOffers() {
          return (
            appStore.state.hasPrepayment &&
            !appStore.state.wish.waiting_list &&
            appStore.state.selectedOffers.some((offer) => offer.has_prepayment)
          )
        },
        get shouldPrepay() {
          return appStore.state.shouldPrepayOffers || appStore.state.shouldPrepayShift
        },
        get shouldPrechargeOrPrepayShift() {
          try {
            return appStore.state.shouldPrecharge || appStore.state.shouldPrepayShift
          } catch {
            return false
          }
        },
        closedBookingsBefore: null,
        closedBookingsAfter: null,
        get days() {
          return appStore.state.months.reduce((sum, month) => [...sum, ...(month.days || [])], [])
        },
        get daysDictionary() {
          return groupByUnique(appStore.state.days, 'date')
        },
        dailyAvailabilities: {},
        months: [],
        get currentMonthKey() {
          return appStore.state.wish.day.slice(0, 7)
        },
        get currentMonth() {
          return appStore.state.months.find((month) => month.key === appStore.state.currentMonthKey)
        },
        get currentDay() {
          const wishDayUTC = new Date(appStore.state.wish.day)
          wishDayUTC.setTime(wishDayUTC.getTime() + wishDayUTC.getTimezoneOffset() * 60000)
          return appStore.state.dailyAvailabilities[format(wishDayUTC, 'YYYY-MM-DD')]
        },
        custom_field: {},
        optins: [
          {
            type: 'review_mail',
            value: 1
          },
          {
            type: 'review_sms',
            value: 1
          },
          {
            type: 'market_mail',
            value: 0
          },
          {
            type: 'market_sms',
            value: 0
          }
        ],
        getDayFromDate: computedFn((dateString: string) => {
          if (typeof dateString !== 'string') {
            console.log('error with dates', dateString)
          }
          return appStore.state.daysDictionary[dateString] || undefined
        }),
        get countOpenShifts() {
          const day = appStore.state.currentDay
          if (day === null || day === undefined || day.isOpen === 'closed' || !day.shifts) {
            return 0
          }
          return day.shifts.filter((shift) => {
            return appStore.state.getAvailableSlots(shift).length > 0
          }).length
        },
        get isCurrentDayAvailableInSuggestedRestaurants() {
          return Object.values(appStore.state.suggestedAppStores).some((suggestedAppStore) => {
            return suggestedAppStore?.state.isCurrentDayAvailableForCurrentPax
          })
        },
        isShiftSlotsAvailableInSuggestedRestaurants: computedFn(() => {
          return Object.values(appStore.state.suggestedAppStores).some((suggestedAppStore) => {
            return suggestedAppStore?.state.isCurrentDayAvailableForCurrentPax
          })
        }),
        isShiftPassedOrClosed: computedFn((shift) => {
          if (!appStore.state.restaurantTimezone) {
            throw Error('calling isShiftPassedOrClosed before initialization')
          }
          return (
            shift.shift_slots.length === 0 ||
            (isSameDay(
              appStore.state.wish.day,
              formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: appStore.state.restaurantTimezone })
            ) &&
              (appStore.state.currentHour === parseInt(shift.close.slice(0, 2))
                ? appStore.state.currentMinutes > parseInt(shift.close.slice(3))
                : appStore.state.currentHour > parseInt(shift.close.slice(0, 2))))
          )
        }),
        isCurrentDayAvailableInSuggestedRestaurantsBetween: computedFn((open, close) => {
          return Object.values(appStore.state.suggestedAppStores).some((suggestedAppStore) => {
            return suggestedAppStore?.state.isCurrentDayAvailableBetween(open, close)
          })
        }),

        isCurrentDayAvailableBetween: computedFn((open, close) => {
          if (!appStore.state.currentDay) {
            return false
          }
          return appStore.isShiftDayAvailableForCurrentPax(appStore.state.currentDay, open, close) === true
        }),
        get isCurrentDayFinished() {
          if (!appStore.state.currentDay) {
            return false
          }
          return appStore.state.currentDay.shifts.every((shift) => {
            return appStore.state.isShiftPassedOrClosed(shift)
          })
        },
        get isCurrentDayOpen() {
          return (
            appStore.state.isCurrentDayAvailableForCurrentPax ||
            appStore.state.isCurrentDayWaitlistAvailableForCurrentPax
          )
        },

        get isCurrentDayAvailableForCurrentPax() {
          if (!appStore.state.currentDay) {
            return false
          }
          return appStore.isShiftDayAvailableForCurrentPax(appStore.state.currentDay) === true
        },
        get shouldLoadSuggestedRestaurants() {
          if (!appStore.state.currentDay) {
            return false
          }
          return (
            appStore.state.currentDay.shifts.length === 0 ||
            appStore.state.currentDay.shifts?.some((shift) => {
              return (
                appStore.state.getAllAvailableSlots(shift).filter((slot) => appStore.state.isSlotAvailable(slot, shift))
                  .length === 0
              )
            })
          )
        },
        get isCurrentDayWaitlistAvailableForCurrentPax() {
          if (!appStore.state.currentDay) {
            return false
          }
          return appStore.isShiftDayWaitlistAvailableForCurrentPax(appStore.state.currentDay) === true
        },

        isSummaryDateAvailableForCurrentPax: computedFn(
          (dateString: string) => {
            if (
              appStore.state.isSummaryDateNotOpenYet(dateString) ||
              appStore.state.isSummaryDateNotOpenAnymore(dateString)
            ) {
              return false
            }

            const day = appStore.state.getDayFromDate(dateString)
            if (day === undefined) {
              return false
            }
            return appStore.isSummaryDayAvailableForCurrentPax(day) === true
          },
          { keepAlive: true }
        ),

        isSummaryDateWaitlistAvailableForCurrentPax: computedFn(
          (dateString) => {
            if (
              appStore.state.isSummaryDateNotOpenYet(dateString) ||
              appStore.state.isSummaryDateNotOpenAnymore(dateString)
            ) {
              return false
            }

            const day = appStore.state.getDayFromDate(dateString)
            if (day === undefined) {
              return false
            }
            return appStore.isSummaryDayWaitlistAvailableForCurrentPax(day) === true
          },
          { keepAlive: true }
        ),
        isSummaryDateFull: computedFn(
          (dateString) => {
            const day = appStore.state.getDayFromDate(dateString)
            if (day === undefined) {
              return false
            }
            if (
              appStore.state.isSummaryDateClosed(dateString) ||
              appStore.state.isSummaryDateNotOpenYet(dateString) ||
              appStore.state.isSummaryDateNotOpenAnymore(dateString)
            ) {
              return false
            }
            return (
              day.shifts &&
              day.shifts.every(
                (shift) => shift.possible_guests.length === 0 && shift.waitlist_possible_guests.length === 0
              )
            )
          },
          { keepAlive: true }
        ),
        isSummaryDateNotOpenYet: computedFn(
          (dateString) => {
            const day = appStore.state.getDayFromDate(dateString)
            if (day === undefined) {
              return false
            }
            return (
              day.shifts.length > 0 &&
              day.shifts.every((shift) => {
                if (!shift.bookable_from) {
                  return false
                }
                if (!appStore.state.restaurantTimezone) {
                  throw Error('calling isSummaryDateNotOpenYet before initialization')
                }
                const bookableFrom = parseFromTimeZone(shift.bookable_from, {
                  timeZone: appStore.state.restaurantTimezone
                })
                return isBefore(new Date(), bookableFrom)
              })
            )
          },
          { keepAlive: true }
        ),
        isSummaryDateNotOpenAnymore: computedFn(
          (dateString) => {
            const day = appStore.state.getDayFromDate(dateString)
            if (day === undefined) {
              return false
            }
            return (
              day.shifts.length > 0 &&
              day.shifts.every((shift) => {
                if (!shift.bookable_to) {
                  return false
                }
                if (!appStore.state.restaurantTimezone) {
                  throw Error('calling isSummaryDateNotOpenAnymore before initialization')
                }
                const bookableTo = parseFromTimeZone(shift.bookable_to, {
                  timeZone: appStore.state.restaurantTimezone
                })
                return isAfter(new Date(), bookableTo)
              })
            )
          },
          { keepAlive: true }
        ),
        isSummaryDateFullPax: computedFn(
          (dateString) => {
            const day = appStore.state.getDayFromDate(dateString)
            if (day === undefined) {
              return false
            }
            if (
              appStore.state.isSummaryDateNotOpenYet(dateString) ||
              appStore.state.isSummaryDateNotOpenAnymore(dateString) ||
              appStore.state.isSummaryDateClosed(dateString)
            ) {
              return false
            }
            return (
              !appStore.state.isSummaryDateFull(dateString) &&
              !appStore.state.isSummaryDateAvailableForCurrentPax(dateString) &&
              !appStore.state.isSummaryDateWaitlistAvailableForCurrentPax(dateString)
            )
          },
          { keepAlive: true }
        ),
        isSummaryDateClosed: computedFn(
          (date) => {
            const day = appStore.state.getDayFromDate(date)
            if (day === undefined) {
              return false
            }
            return (
              !appStore.state.isSummaryDateNotOpenYet(date) &&
              appStore.isSummaryDayAvailableForCurrentPax(day) === 'closed'
            )
          },
          { keepAlive: true }
        ),
        isDateInThePast: computedFn(
          (date) => {
            if (!appStore.state.restaurantTimezone) {
              throw Error('calling isDateInThePast before initialization')
            }
            return date < formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: appStore.state.restaurantTimezone })
          },
          { keepAlive: true }
        ),
        isDateToday: computedFn(
          (date) => {
            if (!appStore.state.restaurantTimezone) {
              throw Error('calling isDateToday before initialization')
            }
            return date === formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: appStore.state.restaurantTimezone })
          },
          { keepAlive: true }
        ),
        get openDays() {
          return appStore.state.months
            .flatMap((month) => month.days)
            .map((day) => day.date)
            .filter((dateString) => {
              const shiftDay = appStore.state.dailyAvailabilities[dateString]
              return (
                !(shiftDay && appStore.isShiftDayAvailableForCurrentPax(shiftDay) !== true) &&
                appStore.state.isSummaryDateAvailableForCurrentPax(dateString)
              )
            })
            .sort((a, b) => +new Date(a) - +new Date(b))
        },
        isOfferRequiredInShift: computedFn((shift) => {
          return this.state.isOfferRequiredInShiftForPax(shift, this.state.wish.pax)
        }),
        isOfferRequiredInShiftForPax: computedFn((shift, pax) => {
          return shift.is_offer_required && (shift.offer_required_from_pax ?? 0) <= pax
        }),
        getAvailableSlots: computedFn((shift) => {
          return shift.shift_slots
            .filter((slot) => {
              if (
                appStore.state.restaurantId &&
                ['348663', '347950', '352789', '353584', '353321'].includes(appStore.state.restaurantId)
              ) {
                const evenAvailability = appStore.evenAvailability(
                  slot,
                  shift.total,
                  appStore.shiftEvenOccupation(shift)
                )
                return (
                  slot.possible_guests.includes(appStore.state.wish.pax) &&
                  evenAvailability >= appStore.state.wish.pax &&
                  !slot.marked_as_full
                )
              } else {
                return (
                  slot.possible_guests.includes(appStore.state.wish.pax) &&
                  !slot.marked_as_full &&
                  (!appStore.state.wish.room_id ||
                    (slot.available_rooms !== undefined && appStore.state.wish.pax in slot.available_rooms
                      ? slot.available_rooms[appStore.state.wish.pax]
                      : []
                    ).includes(appStore.state.wish.room_id))
                )
              }
            })
            .filter(
              (slot) =>
                !appStore.state.isOfferRequiredInShift(shift) ||
                appStore.state.isShiftSlotAndWishCompatibleWithOffers(shift, slot)
            )
        }),
        getAllAvailableSlots: computedFn((shift) => {
          return Array.from(
            new Set([...appStore.state.getAvailableSlots(shift), ...appStore.state.getAvailableWaitlistSlots(shift)])
          ).sort((a, b) => {
            if (a.name.length !== b.name.length) {
              return a.name.length < b.name.length ? -1 : 1
            }
            return a.name < b.name ? -1 : 1
          })
        }),
        getStock: (offer) => {
          if (offer.stock < (offer.capacity?.min || 1)) {
            return 0
          }

          return Math.min(offer.stock, offer.capacity?.max ?? Infinity)
        },
        isShiftSlotAndWishCompatibleWithOffers: (shift, slot, type) => {
          return appStore.state.isShiftSlotPaxAndRoomCompatibleWithAvailableOffers(
            shift,
            slot,
            appStore.state.wish.pax,
            appStore.state.wish.room_id,
            type
          )
        },
        isOffersSelectionAvailableWithPax: (offers, pax) => {
          const areAllOffersVisible = offers.every(
            (offer) =>
              (!offer.config?.min_pax_available || offer.config?.min_pax_available <= pax) &&
              (!offer.config?.max_pax_available || offer.config?.max_pax_available >= pax)
          )

          const areAllOffersAvailable = offers.every((offer) => {
            return appStore.state.getStock(offer) >= offer.count
          })

          const isSameForAllOffers = offers.filter((offer) => offer.config?.is_same_for_all)
          const isThereAtMostOneIsSameForAllOffer = isSameForAllOffers.length <= 1
          const areAllIsSameForAllOffersAvailable = isSameForAllOffers.every((offer) => {
            return pax === offer.count
          })

          return (
            areAllOffersVisible &&
            areAllOffersAvailable &&
            isThereAtMostOneIsSameForAllOffer &&
            areAllIsSameForAllOffersAvailable
          )
        },
        isOffersSetAvailableWithPax: (offers, pax, isOfferRequiredInShift) => {
          const visibleOffers = offers.filter(
            (offer) =>
              (!offer.config?.min_pax_available || offer.config?.min_pax_available <= pax) &&
              (!offer.config?.max_pax_available || offer.config?.max_pax_available >= pax)
          )

          const isSameForAllOffers = visibleOffers.filter((offer) => offer.config?.is_same_for_all)
          const otherOffers = visibleOffers.filter((offer) => !offer.config?.is_same_for_all)

          if (
            isSameForAllOffers.some((offer) => {
              return appStore.state.getStock(offer) >= pax
            })
          ) {
            return true
          }
          const minOfferSelections = isOfferRequiredInShift ? pax : 1
          return otherOffers.reduce((stock, offer) => stock + appStore.state.getStock(offer), 0) >= minOfferSelections
        },
        isOffersSetValidWithRoomId: (offers, roomId) => {
          return offers.every((offer) => {
            return !offer.has_specific_rooms || offer.rooms.includes(roomId)
          })
        },
        isOffersSetValidWithRooms: (offers, rooms) => {
          if (!appStore.state.hasStockTable) {
            return true
          }
          return rooms.some((room) => {
            return appStore.state.isOffersSetValidWithRoomId(offers, room.id)
          })
        },
        isOffersSetValidWithLimitedToPax: (offers, pax) => {
          const limitedToPaxOffers = offers.filter((offer) => offer.config?.is_limited_to_pax)
          const sumPax = limitedToPaxOffers.reduce((sum, offer) => sum + offer.count, 0)
          return sumPax <= pax
        },
        isOffersSetValidWithPax: (offers, pax) => {
          if (!this.state.isOffersSelectionAvailableWithPax(offers, pax)) {
            return false
          }

          return (
            offers.every((offer) => {
              return (
                (offer.capacity?.min || 0) <= offer.count &&
                offer.count <= offer.stock &&
                (offer.config?.is_same_for_all ? offer.count === pax : true)
              )
            }) && offers.filter((offer) => offer.config?.is_same_for_all).length <= 1
          )
        },
        getRoomsCompatibleWithPaxAndSelectedRoom(shift, slot, pax, roomId, type = 'booking') {
          const roomsCompatibleWithPax = appStore.state.rooms.filter((room) => {
            if (type === 'waitlist') {
              return appStore.state.isRoomBookableInShift(room, shift)
            }
            const roomIds =
              slot.available_rooms !== undefined && pax in slot.available_rooms ? slot.available_rooms[pax] : []
            return roomIds.includes(room.id)
          })

          return roomId !== null ? roomsCompatibleWithPax.filter((room) => room.id === roomId) : roomsCompatibleWithPax
        },

        /*
         * This function is used to check if the selected offers are compatible with the selected shift and slot
         * It doesn't check that the cart is complete, it only checks that the selected offers are available
         * - Check that all the offers are in the same room in an available one
         * - Check that the offers are available for the selected pax
         */
        isOffersSetValidWithShiftSlotPaxAndRoom: (offers, shift, slot, pax, roomId, type = 'booking') => {
          const {
            getRoomsCompatibleWithPaxAndSelectedRoom,
            isOffersSetValidWithRooms,
            isOffersSelectionAvailableWithPax,
            isOffersSetValidWithLimitedToPax
          } = appStore.state
          const rooms = getRoomsCompatibleWithPaxAndSelectedRoom(shift, slot, pax, roomId, type)
          return (
            isOffersSetValidWithRooms(offers, rooms) &&
            isOffersSetValidWithLimitedToPax(offers, pax) &&
            isOffersSelectionAvailableWithPax(offers, pax)
          )
        },
        isShiftSlotPaxAndRoomCompatibleWithAvailableOffers: (shift, slot, pax, roomId, type = 'booking') => {
          const {
            getRoomsCompatibleWithPaxAndSelectedRoom,
            computeOffersFromShiftAndSlot,
            isOfferRequiredInShiftForPax,
            isOffersSetAvailableWithPax,
            hasStockTable
          } = appStore.state
          const offers = computeOffersFromShiftAndSlot(shift, slot)
          const isOfferRequiredInShift = isOfferRequiredInShiftForPax(shift, pax)

          if (!hasStockTable) {
            return isOffersSetAvailableWithPax(offers, pax, isOfferRequiredInShift)
          }

          const rooms = getRoomsCompatibleWithPaxAndSelectedRoom(shift, slot, pax, roomId, type)

          return rooms.some((room) => {
            const offersInRoom = offers.filter((offer) => {
              return !offer.has_specific_rooms || offer.rooms.includes(room.id)
            })
            return isOffersSetAvailableWithPax(offersInRoom, pax, isOfferRequiredInShift)
          })
        },
        isSlotAvailableForWaitlist: (slot, shift) => {
          return (
            (slot.waitlist_possible_guests || []).includes(appStore.state.wish.pax) &&
            (!appStore.state.isOfferRequiredInShift(shift) ||
              appStore.state.isShiftSlotAndWishCompatibleWithOffers(shift, slot, 'waitlist'))
          )
        },
        isSlotAvailable: (slot, shift) => {
          return (
            slot.possible_guests.includes(appStore.state.wish.pax) &&
            (!appStore.state.wish.room_id ||
              (slot.available_rooms !== undefined && appStore.state.wish.pax in slot.available_rooms
                ? slot.available_rooms[appStore.state.wish.pax]
                : []
              ).includes(Math.trunc(appStore.state.wish.room_id))) &&
            !slot.marked_as_full &&
            (!appStore.state.isOfferRequiredInShift(shift) ||
              appStore.state.isShiftSlotAndWishCompatibleWithOffers(shift, slot))
          )
        },
        getAvailableWaitlistSlots: computedFn((shift) => {
          return shift.shift_slots.filter((slot) => {
            return appStore.state.isSlotAvailableForWaitlist(slot, shift)
          })
        }),
        get availableOffersFromSelectedSlot() {
          if (!appStore.state.selectedSlot) {
            throw new Error('calling availableOffersFromSelectedSlot without a selected slot')
          }
          return appStore.state.availableOffersFromSlot(appStore.state.selectedSlot)
        },
        get availableOffersFromWish() {
          return appStore.state.availableOffersFromSelectedSlot.filter((offer) => {
            if (!offer.has_specific_rooms || !appStore.state.hasStockTable) {
              return true
            }

            const offerBookableRoomIds = offer.rooms.filter((roomId) => {
              return (appStore.state.selectedShift?.bookable_rooms || []).includes(roomId)
            })

            return (
              offerBookableRoomIds.length > 0 &&
              (!appStore.state.wish.room_id || offerBookableRoomIds.includes(appStore.state.wish.room_id))
            )
          })
        },
        availableOffersFromSlot: (slot): Bookings.SelectedOffer[] => {
          if (!slot || !slot.offers) return []
          return slot.offers
            .map(({ id: offerId, stock, capacity, config }) => {
              const offerFromShift: Bookings.ShiftOffer | undefined = (appStore.state.selectedShift?.offers || []).find(
                (offer) => offer.id === offerId
              )
              if (!offerFromShift) {
                throw new Error('calling availableOffersFromSlot before context loaded')
              }
              return {
                ...offerFromShift,
                ...(capacity ? { capacity } : {}),
                ...(config ? { config } : {}),
                stock,
                id: offerId
              }
            })
            .filter((offer) => {
              return (
                (!offer.config?.min_pax_available || offer.config?.min_pax_available <= appStore.state.wish.pax) &&
                (!offer.config?.max_pax_available || offer.config.max_pax_available >= appStore.state.wish.pax)
              )
            })
        },
        get wishOffersLimitedToPax() {
          return appStore.state.wish.offers.filter((offerWished) => {
            const offer = appStore.state.availableOffersFromSelectedSlot.find(
              (item) => item.id === offerWished.offer_id
            )
            return offer && offer.config?.is_limited_to_pax === true
          })
        },
        get totalCountLimitedToPaxWishOffers() {
          return appStore.state.wishOffersLimitedToPax.reduce((acc, o) => acc + o.count, 0)
        },
        computeProductPrice: (product, quantity) => {
          return product.price * quantity
        },
        productPriceFormatted: (product, quantity) => {
          return appStore.state.formattedPrice(appStore.state.computeProductPrice(product, quantity))
        },
        productUnitPriceFormatted: (product) => {
          return appStore.state.formattedPrice(product.price)
        },
        get cartPrice() {
          return appStore.state.formattedPrice(
            appStore.state.selectedProducts.reduce((sum, product) => {
              return sum + appStore.state.computeProductPrice(product, product.quantity)
            }, 0)
          )
        },
        get orderAmount() {
          if (appStore.state.order.amount === null || appStore.state.order.currency === null) {
            throw new Error('call orderAmount without any order')
          }
          return appStore.state.formattedPrice(parseInt(appStore.state.order.amount), appStore.state.order.currency)
        },
        computeOfferPrice: (offer) => {
          return Math.trunc(offer.charge_per_guests ?? 0) * offer.count
        },
        offerUnitPriceFormatted: (offer) => {
          return appStore.state.formattedPrice(Math.trunc(offer.charge_per_guests ?? 0))
        },
        get formattedPrepaymentPrice() {
          return appStore.state.formattedPrice(appStore.state.prepaymentPrice)
        },
        get prepaymentPrice() {
          if (!appStore.state.shouldPrepay) {
            throw new Error('call prepaymentPrice without shouldPrepay')
          }
          if (appStore.state.shouldPrepayOffers) {
            return appStore.state.selectedOffersWithPrepaymentPrice
          }
          return appStore.state.shiftPrepaymentPrice
        },
        get selectedOffersFormattedPrice() {
          return appStore.state.formattedPrice(appStore.state.selectedOffersPrice)
        },
        get selectedOffersPrice() {
          return appStore.state.selectedOffers.reduce((sum, offer) => {
            return sum + appStore.state.computeOfferPrice(offer)
          }, 0)
        },
        get selectedOffersWithPrepaymentPrice() {
          return appStore.state.selectedOffers.reduce((sum, offer) => {
            if (offer.has_prepayment) {
              return sum + appStore.state.computeOfferPrice(offer)
            }
            return sum
          }, 0)
        },
        get shouldShowOffersTotalAmount() {
          return appStore.state.selectedOffers.some((offer) => offer.charge_per_guests !== null)
        },
        get shiftPrepaymentPrice() {
          if (!appStore.state.selectedShift?.prepayment_param?.charge_per_guests) {
            throw new Error(
              'calling shiftPrepaymentPrice without a prepayment_param.charge_per_guests in a selected shift'
            )
          }
          return appStore.state.selectedShift.prepayment_param.charge_per_guests * appStore.state.wish.pax
        },
        get quotationAmountFormatted() {
          if (!appStore.state.quotation?.amount || !appStore.state.quotation?.currency) {
            return ''
          }
          return appStore.state.formattedPrice(appStore.state.quotation.amount, appStore.state.quotation.currency)
        },
        formattedPrice: (price, currency = appStore.state.currency) => {
          return currency ? formatPrice(price, currency, appStore.state.language) : ''
        },
        get bookingDuration() {
          const shift = appStore.state.selectedShift
          const slot = appStore.state.selectedSlot

          if (!shift || !slot) {
            return null
          }
          const capacity = slot.capacity ?? shift.capacity
          return getBookingDuration(appStore.state.wish.pax, capacity)
        },

        googleCalUrl: () => {
          const {
            name,
            city,
            bookingDuration,
            restaurantTimezone,
            wish: { day, slot }
          } = appStore.state
          if (!day || !slot) {
            throw new Error('calling googleCalUrl without a selected day or slot')
          }
          if (!restaurantTimezone) {
            throw new Error('calling googleCalUrl without a restaurantTimezone')
          }
          const startDate = computeDate(day, slot, restaurantTimezone)

          const event: CalendarEvent = {
            title: i18n.t('booking_cal_title', { restaurantName: name, city }),
            description:
              i18n.t('booking_cal_description', {
                restaurantName: name,
                city,
                pax: appStore.state.wish.pax
              }) ?? undefined,
            start: startDate.getTime(),
            duration: bookingDuration === null ? undefined : [bookingDuration / 60, 'hours']
          }
          return google(event)
        },

        formData: {
          firstname: '',
          lastname: '',
          civility: '',
          phone: '',
          printedPhone: '',
          phone_number: '',
          tmp_phone: '',
          tmp_phone_valid: '',
          email: '',
          country: '',
          comment: '',
          custom_field: {},
          save_info: false,
          moment: '',
          type_client: '',
          type_event: '',
          budget: '',
          zip: '',
          event_type: '',
          eula_accepted: false,
          consent_loosing_confirmation: false,
          optins: [
            {
              type: 'review_mail',
              value: 1
            },
            {
              type: 'review_sms',
              value: 1
            },
            {
              type: 'market_mail',
              value: 0
            },
            {
              type: 'market_sms',
              value: 0
            }
          ]
        },
        selectedCalendarLink: null,
        restaurantTimezone: undefined,
        ebType: '',
        has_adyen_for_prepayment: undefined,
        waiting_list: undefined,
        partner_id: undefined,
        roomsById: {},
        hasChargeAccount: undefined,
        imprint_param: undefined,
        stripe_client_secret: undefined,
        pendingBooking: undefined,
        booking: undefined,
        offerSelectionHasBeenCleared: false,
        voucherCodes: { validatedVoucherCodes: [], bookingVoucherCodes: [] }
      },
      undefined,
      undefined
    )
  }

  isInSdkIframe = (): boolean => {
    return isInSdkIframe(this)
  }

  @action
  setSdkLocationHref: Actions['setSdkLocationHref'] = (sdkLocationHref: string) => {
    this.state.sdkLocationHref = sdkLocationHref
  }

  @action
  setAuthToken: Actions['setAuthToken'] = async () => {
    this.state.timestamp = Number(new Date())
    this.state.authToken = createHmacString(
      SECRET_KEY,
      JSON.stringify({
        restaurantId: this.state.restaurantId,
        timestamp: this.state.timestamp
      })
    )
  }

  @action
  prefillFormData: Actions['prefillFormData'] = (ctx: NextPageContext) => {
    // if cookie we fill this.state.formData
    const cookies = parseCookies(ctx)
    let formDataFromCookies = {}
    try {
      formDataFromCookies = JSON.parse(cookies.formDataFromCookies)
    } catch (e) {
      //
    }

    const formDataToOverride = {
      ...(!!(!ctx.query.firstname && !ctx.query.lastname) && formDataFromCookies),
      ...ctx.query
    }

    Object.keys(formDataToOverride).forEach((key) => {
      if (key in this.state.wish) {
        if (['pax', 'room_id'].includes(key)) {
          this.state.wish[key] = parseInt(formDataToOverride[key])
        } else if (['waiting_list'].includes(key)) {
          this.state.wish[key] = formDataToOverride[key] === 'true'
        } else {
          this.state.wish[key] = formDataToOverride[key]
        }
      }
    })
    Object.keys(formDataToOverride).forEach((key) => {
      if (key in this.state.formData) {
        this.state.formData[key] = formDataToOverride[key]
      }
    })
    Object.keys(formDataToOverride).forEach((key) => {
      if (key.startsWith('custom_field.')) {
        this.state.formData.custom_field[key.replace('custom_field.', '')] = formDataToOverride[key]
      }
    })
  }

  evenize = (value: number) => (!(value % 2) ? value : value + 1)

  /**
   * This function do ......
   */
  evenAvailability = (slot, shiftMax, shiftOccupation) => {
    const total = slot.occupation.scheduled.bookings.reduce((sum, booking) => {
      return sum + this.evenize(booking.nb_guests)
    }, 0)

    const slotCapacity = slot.capacity.total_per_slot - total
    const shiftCapacity = shiftMax - shiftOccupation
    const capacity = Math.min(slotCapacity, shiftCapacity)

    return capacity
  }

  shiftEvenOccupation = (shift) => {
    return shift.shift_slots.reduce((sum, slot) => {
      return (
        sum +
        slot.occupation.scheduled.bookings.reduce((sum, booking) => {
          return sum + this.evenize(booking.nb_guests)
        }, 0)
      )
    }, 0)
  }

  shiftEvenAvailable = (shift) => {
    const shiftEvenAvailable =
      !!shift.shift_slots &&
      shift.shift_slots.some((slot) => {
        const evenAvailability = this.evenAvailability(slot, shift.total, this.shiftEvenOccupation(shift))
        return evenAvailability >= this.state.wish.pax
      })
    return shiftEvenAvailable
  }

  isRequired: Actions['isRequired'] = (field) => {
    if (this.state.isInUpdateFlow) {
      return false
    }
    if (field.startsWith('custom_field_privatisation.')) {
      return true
    }
    return this.state.mandatoryFields[field] !== undefined && this.state.mandatoryFields[field] === 'required'
  }

  isDisplayed: Actions['isDisplayed'] = (field) => {
    if (field.startsWith('custom_field_privatisation.')) {
      return true
    }
    return this.state.mandatoryFields[field] !== undefined && this.state.mandatoryFields[field] !== 'hidden'
  }

  @action getCommentSpecific: Actions['getCommentSpecific'] = async (day) => {
    if (day in this.state.restaurantSpecificCommentsByDay) {
      return Promise.resolve()
    }
    try {
      return api
        .get<{
          comment_translations: Partial<Record<AvailableLanguages, string | null>>
        }>(`getCommentSpecific?restaurantId=${this.state.restaurantId}&date=${day}`)
        .then((res) => {
          this.state.restaurantSpecificCommentsByDay[day] = res.data?.comment_translations ?? null
        })
    } catch (e) {
      if (e.response) {
        console.log(e.response.message)
      } else {
        console.log(e.message)
      }
    }
  }

  @action createQuotation: Actions['createQuotation'] = () => {
    const data = this.createBookingData()
    return api
      .post<
        {
          metaData: { saved: boolean; client_secret: string }
          data: State['quotation']
        },
        typeof data
      >(`quotation?restaurantId=${this.state.restaurantId}`, data)
      .then((res) => {
        if (!(res.data && res.data.metaData && res.data.metaData.saved) || !(res.data.data && res.data.data.id)) {
          throw new Error('Error while creating quotation')
        } else {
          this.state.quotation = {
            id: res.data.data.id,
            amount: res.data.data.amount,
            currency: res.data.data.currency,
            clientSecret: res.data.metaData.client_secret
          }
        }
      })
  }

  @action createOrder: Actions['createOrder'] = () => {
    const data = this.createOrderData()

    return api
      .post<
        {
          metaData: { saved: boolean }
          data: Order
        },
        typeof data
      >(`order?restaurantId=${this.state.restaurantId}`, data)
      .then((res) => {
        if (!(res.data && res.data.metaData && res.data.metaData.saved)) {
          throw new Error('Error creating order')
        } else {
          this.state.order = observable({ ...res.data.data })
        }
      })
  }

  @action checkoutOrder: Actions['checkoutOrder'] = async () => {
    const response = await api.post<
      {
        metaData: { requires_action: boolean; stripe_client_secret: string }
        data: ErrorOrResponseData & { id: string; customer_sheet: CustomerSheet }
      },
      { id: number | null; order_number: number | null; payment_intent_id?: number; payment_method_id?: number | null }
    >(`checkout-order?restaurantId=${this.state.restaurantId}`, {
      id: this.state.order.id,
      order_number: this.state.order.number,
      ...(this.state.paymentIntentId
        ? {
            payment_intent_id: this.state.paymentIntentId
          }
        : {
            payment_method_id: this.state.paymentMethodId
          })
    })

    if (response.data.metaData.requires_action) {
      // 3DS
      return response.data.metaData
    } else if (response.data.data.error || !response.data.data.id) {
      this.state.error = errorMessageHandler(response.data.data)
      this.state.paymentIntentId = null
      this.state.paymentMethodId = null
    } else {
      this.state.error = null
      this.state.createdCustomerSheet = response.data.data.customer_sheet
      this.state.createdCustomerSheet.optins = response.data.data.customer_sheet.optins
      this.state.createdCustomerSheet.id = response.data.data.customer_sheet.id
      this.state.stripe_client_secret = response.data.metaData?.stripe_client_secret

      return response.data.data
    }
  }

  @action getWidgetParams: Actions['getWidgetParams'] = async () => {
    try {
      // @ts-ignore any can be removed here when typing api
      const res: any = await api.get(`getWidgetParams?restaurantId=${this.state.restaurantId}`)
      this.state.name = res.data.name
      this.state.isDisabled = res.data.isDisabled
      this.state.shouldDisplayShopVoucher = res.data.shouldDisplayShopVoucher
      this.state.websiteUrl = res.data.website
      this.state.city = res.data.city
      this.state.address = res.data.address
      this.state.address2 = res.data.address2
      this.state.zip = res.data.zip
      this.state.acl.replace(res.data.acl)
      this.state.restaurantComment = res.data.restaurantComment
      this.state.language_availabilities = res.data.language_availabilities
      this.state.logo = res.data.logo
      this.state.phone = res.data.phone
      this.state.restaurantLanguage = res.data.restaurantLanguage
      this.state.restaurantCountry = res.data.restaurantCountry
      this.state.restaurantTimezone = res.data.restaurantTimezone
      this.state.shift_limit = res.data.shift_limit
      this.state.is_white_label = res.data.is_white_label
      this.state.currency = res.data.currency
      this.state.analytics = res.data.analytics
      this.state.analytics_tag = res.data.analytics_tag
      this.state.hasChargeAccount = res.data.has_charge_account || false
      this.state.prepayment_param = res.data.prepayment_param || null
      this.state.imprint_param = res.data.imprint_param || null
      this.state.groups = res.data.groups || []
      this.state.publishers = res.data.publishers || []
      this.state.hasConnectedVouchers = res.data.has_connected_vouchers || false
      this.state.has_adyen_for_prepayment = res.data.has_adyen_for_prepayment
      this.state.rooms = res.data.rooms || []
      this.state.roomsById = ((res.data.rooms || []) as Bookings.Room[]).reduce((acc, room) => {
        acc[room.id] = room
        return acc
      }, {})
      if (!(this.state.query && this.state.query.srid)) {
        this.state.suggestedRestaurantIds = (res.data.suggested_restaurants || []).map(({ id }) => id)
      }
      const now = parseFromTimeZone(new Date().toISOString(), { timeZone: 'UTC' })
      this.state.today = convertToTimeZone(now, { timeZone: this.state.restaurantTimezone ?? 'UTC' })
      this.state.nowLocal = convertToTimeZone(now, { timeZone: this.state.restaurantTimezone ?? 'UTC' })
      this.state.hasRoomSelection = res.data.has_room_selection_on_widget
      this.state.hasStockTable = res.data.has_stock_table
      this.state.isRoomMandatory = res.data.has_room_selection_on_widget && res.data.is_room_selection_mandatory
      this.state.isTestRestaurant = res.data.is_test_restaurant
      if (this.state.isTestRestaurant) {
        this.state.stripePublishableKey = STRIPE_TEST_PUBLISHABLE_KEY
        this.state.adyenEnv = ADYEN_TEST_ENV
        this.state.adyenPublicKey = ADYEN_TEST_PUBLIC_KEY
      } else {
        this.state.stripePublishableKey = STRIPE_PUBLISHABLE_KEY
        this.state.adyenEnv = ADYEN_ENV
        this.state.adyenPublicKey = ADYEN_PUBLIC_KEY
      }
      if (
        !(this.state.query && this.state.query.pax) &&
        this.state.shift_limit &&
        this.state.shift_limit.min &&
        this.state.shift_limit.max
      ) {
        this.state.wish.pax = this.state.previousWish.pax
          ? this.state.previousWish.pax
          : Math.min(Math.max(2, parseInt(this.state.shift_limit.min)), parseInt(this.state.shift_limit.max))
      }
      this.state.tagManager = res.data.tagManager
      if (this.state.query && this.state.query.pid === 'facebook' && this.state.query.pixelid) {
        this.state.facebookPixel = this.state.query.pixelid
      } else {
        this.state.facebookPixel = res.data.facebook_pixel_id
      }
      this.state.closedBookingsBefore = res.data.closedBookingsBefore
      this.state.closedBookingsAfter = res.data.closedBookingsAfter
      this.state.notificationSubscriptions = res.data.notification_subscriptions
      const mandatories = await api.get<{ data: Record<string, string> }>(
        `getMandatoryFields?restaurantId=${this.state.restaurantId}`
      )
      this.state.mandatoryFields = Object.keys(mandatories.data).reduce((mandatoryObject, fieldKey) => {
        if (
          !fieldKey.startsWith('custom_field.') ||
          res.data.custom_field_reservation.some((customField) => fieldKey === `custom_field.${customField.slug}`)
        ) {
          mandatoryObject[fieldKey] = mandatories.data[fieldKey]
        }
        return mandatoryObject
      }, {})

      this.state.formValidationError = {
        eula_accepted: this.state.formData.eula_accepted,
        consent_loosing_confirmation: this.state.formData.consent_loosing_confirmation,
        ...toJS(Object.keys(toJS(this.state.mandatoryFields)))
          .filter((key) => this.isRequired(key))
          .reduce((obj, val) => {
            obj[val] = false
            return obj
          }, {})
      }

      this.state.customFields = res.data.custom_field_reservation && this.getFields(res.data.custom_field_reservation)
      this.state.customFieldsPrivatisation =
        res.data.custom_field_privatisation && this.getFieldsPrivatisation(res.data.custom_field_privatisation)
      this.state.widgetParameters.primaryColor = res.data.primaryColor

      if (!this.state.acl.includes('suggested_restaurants')) {
        this.state.suggestedRestaurantIds = []
      }

      if (!(this.state.query && this.state.query.lang)) {
        // MODIF FOR NEW LANGUAGES
        if (this.state.language_availabilities.length) {
          if (this.state.language_availabilities.includes(res.data.language)) {
            this.state.language = res.data.language
          } else {
            this.state.language = 'en'
          }
        } else {
          // FALLBACK IN CASE WE DON'T HAVE ANY AVAILABLE LANGUAGES
          this.state.language = normalizeLang(res.data.language)
        }
      }
      if (res.data.voucher_param) {
        this.state.voucherParam = res.data.voucher_param
      }
    } catch (e) {
      if (e.response) {
        console.log(e.response.message)
      } else {
        console.log(e.message)
      }
      throw e
    }
  }

  @action getCalendarLink: Actions['getCalendarLink'] = (type) => {
    const {
      restaurantId,
      bookingUuid,
      name,
      city,
      bookingDuration,
      restaurantTimezone,
      wish: { day, slot },
      language
    } = this.state
    if (!day || !slot) {
      throw new Error('calling getCalendarLink without a selected day or slot')
    }
    if (!restaurantTimezone) {
      throw new Error('calling getCalendarLink before initialization')
    }
    const startDate = computeDate(day, slot, restaurantTimezone)

    const generateEvent = (type: CalendarType): CalendarEvent => {
      const description =
        restaurantId && bookingUuid
          ? i18n.t(
              type === 'GOOGLE'
                ? 'booking_google_cal_description_with_booking_link'
                : 'booking_cal_description_with_booking_link',
              {
                restaurantName: name,
                city,
                pax: this.state.wish.pax,
                url: landingPage(language, restaurantId, bookingUuid)
              }
            )
          : i18n.t('booking_cal_description', {
              restaurantName: name,
              city,
              pax: this.state.wish.pax
            })

      const event: CalendarEvent = {
        title: i18n.t('booking_cal_title', { restaurantName: name, city }),
        description,
        start: startDate.getTime(),
        duration: bookingDuration === null ? undefined : [bookingDuration / 60, 'hours']
      }
      return event
    }

    switch (type) {
      case 'GOOGLE':
        return google(generateEvent(type))

      case 'OUTLOOK':
        return outlook(generateEvent(type))

      case 'ICS':
        return ics(generateEvent(type))
      default:
        throw new Error('unsupported calendar type')
    }
  }

  @action getMarketingLink: Actions['getMarketingLink'] = (origin: string) => {
    return `https://www.zenchef.com/welcome?rid=${this.state.restaurantId}&restaurant_name=${this.state.name}&utm_source=widget&utm_content=${origin}`
  }

  @action selectWishDay: Actions['selectWishDay'] = async (day) => {
    await this.getDailyAvailabilities(day)
    this.state.wish.day = day
  }

  getFields = (custom_field_reservation) => {
    // filter type === 'widget'
    return custom_field_reservation.map((customField) => {
      if (!customField.options || customField.options.length === 0) {
        if (customField.type === 'checkbox') customField.displayType = 'checkbox'
        else customField.displayType = 'text'
      } else {
        if (customField.type === 'checkbox') customField.displayType = 'radio'
        else customField.displayType = 'select'
      }
      customField.enabled = this.state.mandatoryFields[`custom_field.${customField.slug}`] === 'displayed'
      customField.required = this.state.mandatoryFields[`custom_field.${customField.slug}`] === 'required'
      return customField
    })
  }

  getFieldsPrivatisation = (custom_field_reservation) => {
    // filter type === 'widget'
    return custom_field_reservation.map((customField) => {
      if (!customField.options || customField.options.length === 0) {
        if (customField.type === 'checkbox') customField.displayType = 'checkbox'
        else customField.displayType = 'text'
      } else {
        if (customField.type === 'checkbox') customField.displayType = 'radio'
        else customField.displayType = 'select'
      }
      customField.enabled = true
      customField.required = true
      return customField
    })
  }

  updateBookingData = (): UpdateBookingData => {
    const { comment } = this.state.formData
    const { day, slot: time, pax: nb_guests, room_id, offers } = this.state.wish
    const data: UpdateBookingData = {
      day,
      nb_guests,
      time,
      wish: { booking_room_id: room_id },
      offers,
      comment
    }

    return data
  }

  createBookingData = ({ withEB = false, withPrepayment = false } = {}) => {
    let { comment, firstname, lastname, civility, country, phone_number, email, custom_field } = this.state.formData
    const optins = this.state.formData.optins.filter((optin) => optin.value === 1)
    let { slot: time, pax: nb_guests, day, waiting_list, room_id } = this.state.wish
    const lang = this.state.language
    const { validatedVoucherCodes } = this.state.voucherCodes

    phone_number = phone_number.replace(/\s/g, '')
    if (time?.slice(5, 7) === '+1') {
      day = format(addDays(day, 1), 'YYYY-MM-DD')
      time = time.slice(0, 5)
    }

    const data: CreateBookingPayload = {
      day,
      nb_guests,
      time,
      lang,
      firstname: firstname?.trim(),
      lastname: lastname?.trim(),
      civility,
      country,
      phone_number,
      email: email?.trim(),
      comment,
      custom_field,
      customersheet: {
        firstname: firstname?.trim(),
        lastname: lastname?.trim(),
        civility,
        phone: phone_number,
        email: email?.trim(),
        optins,
        country,
        lang
      },
      ...(room_id ? { wish: { booking_room_id: room_id } } : {}),
      ...(waiting_list ? { phase: 'waiting_list' } : {}),
      offers: this.state.wish.offers,
      partner_id: this.state.partner_id || '1001',
      ...(this.state.incomingCallerId ? { icid: this.state.incomingCallerId } : {}),
      ...(this.state.prescriber_id ? { prescriber_id: this.state.prescriber_id } : {}),
      ...(this.state.sourceRestaurantId ? { source_restaurant_id: this.state.sourceRestaurantId } : {}),
      type: this.state.type
    }

    if (withEB || withPrepayment) {
      data.charge = {
        quotation_id: this.state.quotation?.id,
        ...(this.state.paymentIntentId
          ? {
              payment_intent_id: this.state.paymentIntentId
            }
          : this.state.paymentMethodId
            ? {
                payment_method_id: this.state.paymentMethodId
              }
            : {
                setup_intent_id: this.state.setupIntentId
              })
      }
    }

    if (this.state.restaurantId !== undefined && withEB) {
      data.status = 'waiting'
      data.phase = 'waiting_imprint'
    }

    if (withPrepayment) {
      data.status = 'waiting'
      data.phase = 'waiting_payment'

      const addForcePaymentPrameter = (href: string): string => {
        const url = new URL(href)
        const search = new URLSearchParams(url.search)
        search.set('force-prepayment', '1')
        url.search = search.toString()
        return url.toString()
      }

      const getSuccessUrl = () => {
        if (this.state.sdkLocationHref && this.isInSdkIframe()) {
          return addForcePaymentPrameter(this.state.sdkLocationHref)
        } else {
          return addForcePaymentPrameter(window.location.href)
        }
      }

      data.charge = {
        quotation_id: this.state.quotation?.id,
        successUrl: getSuccessUrl()
      }
    }

    if (validatedVoucherCodes.length) {
      data.voucher_codes = validatedVoucherCodes.map(({ code }) => ({ code }))
    }

    return data
  }

  createOrderData = () => {
    let { firstname, lastname, civility, country, phone_number, email } = this.state.formData
    const optins = this.state.formData.optins.filter((optin) => optin.value === 1)
    const lang = this.state.language

    phone_number = phone_number.replace(/\s/g, '')

    const data = {
      products: this.state.selectedProducts.map(({ id, quantity }) => ({ id, quantity })),
      customer_sheet: {
        firstname,
        lastname,
        civility,
        phone: phone_number,
        email,
        optins,
        country,
        lang
      },
      partner_id: this.state.partner_id || '1001'
    }

    return data
  }

  @action createBooking: Actions['createBooking'] = async (options) => {
    const data = this.createBookingData(options)
    this.state.error = null
    try {
      const response = await api.post<CreateBookingResponse, CreateBookingPayload>(
        `booking?restaurantId=${this.state.restaurantId}`,
        data,
        {
          headers: {
            timestamp: this.state.timestamp,
            'auth-token': this.state.authToken
          }
        }
      )

      this.state.sha256 = response.data.sha256
      const {
        optins: blabla,
        comment: blabla1,
        custom_field: blabla2,
        eula_accepted,
        ...dataToStore
      } = this.state.formData
      const cookieString = this.state.formData.save_info
        ? cookie.serialize('formDataFromCookies', JSON.stringify(dataToStore), {
            domain: BOOKINGS_DOMAIN
          })
        : cookie.serialize('formDataFromCookies', JSON.stringify(dataToStore), {
            domain: BOOKINGS_DOMAIN,
            expires: new Date()
          })
      this.state.cookieString = cookieString
      document.cookie = cookieString

      if (response.data.requires_action) {
        // 3DS case
        return response.data
      } else if (response.data.error || !response.data.id) {
        // booking middleware returns sometimes statusCode 200 without a created booking
        this.handleCreateBookingError(response.data)
        this.state.paymentIntentId = null
        this.state.paymentMethodId = null
      } else {
        this.state.error = null
        this.state.specificErrorKey = null
        this.state.bookingExpiresDate = null
        this.state.postBookingBody = JSON.stringify(data)
        this.state.pendingBookingId = response.data.id
        this.state.bookingUuid = response.data.uuid
        this.state.createdCustomerSheet = {
          optins: response.data.customersheet?.optins,
          id: response.data.customersheet?.id,
          relatedBookingId: response.data.id,
          relatedBookingStatus: response.data.status
        }
        this.state.stripe_client_secret = response.data.charge?.stripe_client_secret
        this.state.paymentExpiresDate = response.data.expires_at ? new Date(response.data.expires_at * 1000) : undefined
        this.state.voucherCodes.bookingVoucherCodes = this.formatVoucherCodesApiToStore(
          response.data.voucher_codes ?? []
        )

        this.sendBookingCreatedEvent()
        return response.data
      }
    } catch (e) {
      if (e.response?.data?.error) {
        const errorResponseData = e.response.data
        this.handleCreateBookingError(errorResponseData)
        this.state.paymentIntentId = null
        this.state.paymentMethodId = null
      } else {
        throw e
      }
    }
  }

  handleCreateBookingError = (errorResponseData: ErrorOrResponseData) => {
    const isBookingAlreadyExistsError =
      errorResponseData.error &&
      'message' in errorResponseData.error &&
      errorResponseData.error?.message === 'booking_already_exists'

    if (
      isBookingAlreadyExistsError &&
      'message' in errorResponseData.error &&
      errorResponseData.error.bookingExpiresAt
    ) {
      this.state.specificErrorKey = errorResponseData.error.message
      this.state.bookingExpiresDate = new Date(errorResponseData.error.bookingExpiresAt * 1000)
      this.state.paymentExpiresDate = null
      this.state.error = null
    } else {
      this.state.error = errorMessageHandler(errorResponseData)
      this.state.specificErrorKey = null
      this.state.bookingExpiresDate = null
      this.state.paymentExpiresDate = null
    }
  }

  hasNotCreatedBooking = (): boolean => {
    return !this.state.postBookingBody
  }

  hasBookingAttributesChanged = (options?: { withPrepayment?: boolean; withEB?: boolean }): boolean => {
    const postBookingBody = this.createBookingData(options)
    return this.state.postBookingBody !== JSON.stringify(postBookingBody)
  }

  @action updateBooking: Actions['updateBooking'] = async () => {
    const {
      bookingUuid,
      restaurantId,
      formData: { optins },
      booking
    } = this.state

    if (!bookingUuid || !booking) {
      throw new Error('bookingUuid is not defined')
    }
    const { customer_sheet_id } = booking
    const data = this.updateBookingData()
    this.state.error = ''

    // We need to update optins separately
    optins.forEach(async ({ value, type }) => {
      const optinValueHasChanged =
        Boolean(value) !== booking.customersheet.optins.find((optin) => optin.type === type)?.value
      if (optinValueHasChanged) {
        await this.patchOptin(type, value, customer_sheet_id)
      }
    })

    const response = await api.patch<{ customersheet: CustomerSheet; id: number; status: string }, typeof data>(
      `bookings/${bookingUuid}`,
      data,
      {
        params: { restaurantId },
        headers: {
          timestamp: this.state.timestamp,
          'auth-token': this.state.authToken
        }
      }
    )
    this.state.createdCustomerSheet = {
      optins: response.data.customersheet?.optins,
      id: response.data.customersheet?.id,
      relatedBookingId: response.data.id,
      relatedBookingStatus: response.data.status
    }

    return response.data
  }

  confirmImprint = async () => {
    const { restaurantId, pendingBookingId: bookingId } = this.state
    const { data } = await api.post<{ booking: { status: string } }, {}>(
      `bookings/${bookingId}/charge/confirmImprint?restaurantId=${restaurantId}`,
      {},
      {
        headers: {
          timestamp: this.state.timestamp,
          'auth-token': this.state.authToken
        }
      }
    )

    this.state.createdCustomerSheet.relatedBookingStatus = data.booking.status
  }

  sendBookingCreatedEvent = () => {
    const { restaurantId, wish, partner_id, incomingCallerId, formData } = this.state
    fireAnalyticEvent(ANALYTICS_EVENTS_NAMES.BOOKING_CREATED, {
      restaurant_id: restaurantId,
      pax: wish.pax,
      date: wish.day,
      time: wish.slot,
      partner_id,
      ...(incomingCallerId ? { incoming_caller_id: incomingCallerId } : {}),
      optin_mail:
        formData.optins && formData.optins.find((optin) => optin.type === 'market_mail')
          ? formData.optins.find((optin) => optin.type === 'market_mail')?.value
          : 0,
      optin_sms:
        formData.optins && formData.optins.find((optin) => optin.type === 'market_sms')
          ? formData.optins.find((optin) => optin.type === 'market_sms')?.value
          : 0
    })
  }

  @action authorizeStripePayment: Actions['authorizeStripePayment'] = async (stripe, cardElement) => {
    const {
      restaurantId,
      createdCustomerSheet: { relatedBookingId: bookingId }
    } = this.state

    const { paymentMethod, error: createPaymentMethodError } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })
    if (createPaymentMethodError) {
      throw createPaymentMethodError
    }

    const { data } = await api.post<
      {
        metaData: { requires_action: boolean; booking_status: string; stripe_client_secret: string }
      },
      { payment_method: string }
    >(
      `bookings/${bookingId}/charge/authorize?restaurantId=${restaurantId}`,
      {
        payment_method: paymentMethod?.id
      },
      {
        headers: {
          timestamp: this.state.timestamp,
          'auth-token': this.state.authToken
        }
      }
    )

    if (!data.metaData?.requires_action) {
      this.state.createdCustomerSheet.relatedBookingStatus = data.metaData.booking_status
      return data
    }

    // handle 3DS
    const { error } = await stripe.handleCardAction(data.metaData?.stripe_client_secret)
    if (error) {
      throw error
    }

    const { data: authorizeResponse } = await api.post<{ metaData: { booking_status: string } }, {}>(
      `bookings/${bookingId}/charge/authorize?restaurantId=${restaurantId}`,
      {},
      {
        headers: {
          timestamp: this.state.timestamp,
          'auth-token': this.state.authToken
        }
      }
    )
    this.state.createdCustomerSheet.relatedBookingStatus = authorizeResponse.metaData.booking_status
    return authorizeResponse
  }

  @action createPrivatisation: Actions['createPrivatisation'] = async () => {
    let {
      firstname,
      lastname,
      phone_number,
      email,
      country,
      comment,
      moment,
      type_client,
      type_event,
      budget,
      zip,
      custom_field
    } = this.state.formData
    const lang = this.state.language

    phone_number = phone_number.replace(/\s/g, '')

    const { pax: nb_guests, day } = this.state.wish
    const data = {
      firstname,
      lastname,
      phone_number,
      email,
      country,
      lang,
      comment,
      moment,
      type_client,
      type_event,
      budget,
      day,
      nb_guests,
      zip,
      custom_field,
      type: this.state.type || 'web',
      partner_id: this.state.partner_id || '1001',
      ...(this.state.icid ? { icid: this.state.icid } : {})
    }
    const response = await api.post<{ error: { message: string | Record<string, string[]> | null } }, typeof data>(
      `privatisation?restaurantId=${this.state.restaurantId}`,
      data
    )
    if (response.data.error) {
      if (undefined !== response.data.error.message) {
        if (typeof response.data.error.message === 'object' && response.data.error.message !== null) {
          for (const index in response.data.error.message) {
            this.state.error +=
              'field ' +
              index +
              ' : ' +
              response.data.error.message[index]
                .map(function (v) {
                  return v.replace('validation.', '')
                })
                .join(',') +
              '\r\n'
          }
        } else if (typeof response.data.error.message === 'string' && response.data.error.message !== null) {
          this.state.error = response.data.error.message
        } else {
          throw new Error('There was an error creating the booking')
        }
      }
    } else this.state.error = null
    return !!response.data
  }

  @action postBookingCharge: Actions['postBookingCharge'] = (data) => {
    return api.post(`charge?restaurantId=${this.state.restaurantId}&bookingId=${this.state.relatedBookingId}`, data)
  }

  @action getDailyAvailabilities: Actions['getDailyAvailabilities'] = async (dateString) => {
    if (this.state.dailyAvailabilities[dateString]) {
      return this.state.dailyAvailabilities[dateString]
    }
    const { bookingUuid, isInUpdateFlow } = this.state

    try {
      const response = await api.get<Day[]>(`getAvailabilities`, {
        params: {
          restaurantId: this.state.restaurantId,
          date_begin: dateString,
          date_end: dateString,
          ...(bookingUuid && isInUpdateFlow ? { bookingUuid } : {})
        }
      })

      const days = response.data

      const dailyAvailability = days[0]

      if (isInUpdateFlow) {
        dailyAvailability.shifts.forEach((shift) => {
          shift.shift_slots.forEach((shiftSlot) => {
            shiftSlot.waitlist_possible_guests = []
          })
        })
      }

      this.state.dailyAvailabilities[dateString] = dailyAvailability
      this.state.isLoading = false

      return Promise.resolve()
    } catch (e) {
      console.log('error in availabilities', e)
      Router.push({
        pathname: '/unavailable',
        query: this.state.query
      })
    }
  }

  @action getAvailabilitiesSummary: Actions['getAvailabilitiesSummary'] = async (
    month = getMonth(new Date()) + 1,
    year = getYear(new Date())
  ) => {
    try {
      const monthStr = ('' + month).padStart(2, '0')
      const monthKey = `${year}-${monthStr}`

      const existingMonth = this.state.months.find((m) => m.key === monthKey)
      if (existingMonth || this.state.currentFetchingMonth === monthKey) {
        return
      }

      this.state.currentFetchingMonth = monthKey
      this.state.isLoading = true

      const dateBegin = `${year}-${monthStr}-01`
      const dateEnd = `${year}-${monthStr}-${getDaysInMonth(new Date(parseInt(`${year}`), parseInt(`${month}`) - 1))}`
      const { bookingUuid, isInUpdateFlow } = this.state

      const response = await api.get<SummaryDay[]>('getAvailabilitiesSummary', {
        params: {
          restaurantId: this.state.restaurantId,
          date_begin: dateBegin,
          date_end: dateEnd,
          ...(bookingUuid && isInUpdateFlow ? { bookingUuid } : {})
        }
      })

      const days: SummaryDay[] = response.data

      if (isInUpdateFlow) {
        days.forEach((day) => {
          day.shifts.forEach((shift) => {
            shift.waitlist_possible_guests = []
          })
        })
      }

      const newMonth = {
        key: monthKey,
        days
      }
      const monthIndex = this.state.months.findIndex((month) => month.key === monthKey)

      if (monthIndex !== -1) {
        this.state.months[monthIndex] = newMonth
      } else {
        this.state.months.push(newMonth)
      }

      if (this.state.currentFetchingMonth === monthKey) {
        this.state.isLoading = false
      }
      return Promise.resolve()
    } catch (e) {
      console.log('error in availabilities', e)
      Router.push({
        pathname: '/unavailable',
        query: this.state.query
      })
    }
  }

  @action getProducts: Actions['getProducts'] = async () => {
    try {
      this.state.isLoading = true
      const response = await api.get<Bookings.Product[]>(`getProducts?restaurantId=${this.state.restaurantId}`)
      this.state.products.replace(response.data)
      return Promise.resolve()
    } catch (e) {
      console.log('error in getProducts', e)
    } finally {
      this.state.isLoading = false
    }
  }

  @action getRestaurantInfo: Actions['getRestaurantInfo'] = () => {
    return this.state.restaurantId
      ? api.get(`getRestaurantInfo?restaurantId=${this.state.restaurantId}`)
      : Promise.reject(new Error('no restaurant id'))
  }

  @action authorizeAdyenPayment: Actions['authorizeAdyenPayment'] = async ({ adyenData, adyenRedirectResult }) => {
    const { restaurantId, pendingBookingId: bookingId } = this.state
    const url = `bookings/${bookingId}/charge/authorize?restaurantId=${restaurantId}`
    const { data } = await api.post(url, { adyenData, adyenRedirectResult })

    return data
  }

  @action getAdyenPaymentMethods: Actions['getAdyenPaymentMethods'] = async () => {
    const url =
      'getAdyenPaymentMethods?restaurantId=' +
      this.state.restaurantId +
      '&amount=' +
      this.state.prepaymentPrice +
      '&shopperLocale=' +
      this.state.language
    const { data } = await api.get(url)

    return data
  }

  @action getBookingByUuid: Actions['getBookingByUuid'] = async (uuid: string) => {
    const url = `bookings/${uuid}`
    const response = await api.get<Bookings.BookingDetails>(url, {
      params: {
        restaurantId: this.state.restaurantId
      }
    })
    this.state.booking = response.data
    return response.data
  }

  @action getBookingByChargeToken: Actions['getBookingByChargeToken'] = async (token) => {
    const url = `getBookingByChargeToken?restaurantId=${this.state.restaurantId}&token=${token}`
    const response = await api.get<
      Omit<Bookings.Wish, 'pax' | 'slot' | 'offers'> & {
        booking_offers: Bookings.WishOffer[]
        time: string
        nb_guests: number
        id: number
        uuid: string
      } & { voucher_codes: VoucherCodeApi[] }
    >(url)

    this.state.pendingBooking = response.data
    this.state.pendingBookingId = response.data.id
    this.state.bookingUuid = response.data.uuid
    const { booking_offers, day, time, nb_guests, lang, voucher_codes } = response.data

    this.state.voucherCodes.bookingVoucherCodes = this.formatVoucherCodesApiToStore(voucher_codes)
    this.state.wish.offers = observable.array(booking_offers)
    await this.getDailyAvailabilities(day)
    this.state.wish.day = day
    this.state.wish.slot = time
    this.state.wish.pax = nb_guests
    this.state.wish.lang = lang
    this.state.wish.waiting_list = false

    return response.data
  }

  @action getBookingBySetupIntentId: Actions['getBookingBySetupIntentId'] = async (setup_intent_id) => {
    const url = `getBookingBySetupIntentId?restaurantId=${this.state.restaurantId}&setup_intent_id=${setup_intent_id}`
    const response = await api.get<
      Omit<Bookings.Wish, 'pax' | 'slot' | 'offers'> & {
        booking_offers: Bookings.WishOffer[]
        time: string
        nb_guests: number
        id: number
        uuid: string
        charge: {
          quotation_id: number
          stripe_client_secret: string
        }
      } & { voucher_codes: VoucherCodeApi[] }
    >(url)

    this.state.pendingBooking = response.data
    this.state.pendingBookingId = response.data.id
    this.state.bookingUuid = response.data.uuid
    const { booking_offers, day, time, nb_guests, lang, voucher_codes } = response.data
    // @ts-expect-error
    this.state.quotation = { id: response.data?.charge?.quotation_id }

    this.state.stripe_client_secret = response.data?.charge?.stripe_client_secret

    this.state.voucherCodes.bookingVoucherCodes = this.formatVoucherCodesApiToStore(voucher_codes)
    this.state.wish.offers = observable.array(booking_offers)
    await this.getDailyAvailabilities(day)
    this.state.wish.day = day
    this.state.wish.slot = time
    this.state.wish.pax = nb_guests
    this.state.wish.lang = lang
    this.state.wish.waiting_list = false

    return response.data
  }

  formatVoucherCodesApiToStore = (voucherCodesApi: VoucherCodeApi[]): BookingVoucherCode[] =>
    voucherCodesApi.map(({ original_amount: originalAmount, used_amount: usedAmount, ...voucherCode }) => ({
      ...voucherCode,
      originalAmount,
      usedAmount
    }))

  isShiftAvailableWithOffers = computedFn((shift: Bookings.Shift) => {
    return (
      !this.state.isOfferRequiredInShift(shift) ||
      shift.shift_slots.some((slot) =>
        this.state.isShiftSlotPaxAndRoomCompatibleWithAvailableOffers(shift, slot, this.state.wish.pax, null)
      )
    )
  })

  isShiftWaitlistAvailableWithOffers = computedFn((shift: Bookings.Shift) => {
    return (
      !this.state.isOfferRequiredInShift(shift) ||
      shift.shift_slots.some((slot) =>
        this.state.isShiftSlotPaxAndRoomCompatibleWithAvailableOffers(
          shift,
          slot,
          this.state.wish.pax,
          null,
          'waitlist'
        )
      )
    )
  })

  isShiftDayAvailableForCurrentPax = (
    dailyAvailability: Day,
    open: string | undefined = undefined,
    close: string | undefined = undefined
  ) => {
    if (
      !dailyAvailability.shifts ||
      !dailyAvailability.shifts.length ||
      !this.state.restaurantTimezone ||
      dailyAvailability.date < formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: this.state.restaurantTimezone })
    ) {
      return 'closed'
    }
    let notFull = false
    let notClosed = false

    if (
      this.state.restaurantId &&
      ['348663', '347950', '352789', '353584', '353321'].includes(this.state.restaurantId)
    ) {
      if (!dailyAvailability.shifts.some(this.shiftEvenAvailable)) {
        return 'full'
      }
    }
    for (const shift of dailyAvailability.shifts) {
      for (const slot of shift.shift_slots) {
        if (
          (!open || slot.slot_name >= open) &&
          (!close || slot.slot_name <= close) &&
          slot.possible_guests.includes(this.state.wish.pax) &&
          !shift.marked_as_full &&
          this.isShiftAvailableWithOffers(shift)
        ) {
          notFull = true
        }
        if (!slot.closed) {
          notClosed = true
        }
      }
    }

    if (!notClosed) {
      return 'closed'
    }
    if (!notFull) {
      return 'full'
    }
    return true
  }

  isShiftDayWaitlistAvailableForCurrentPax = computedFn((dailyAvailability: Day) => {
    if (!this.state.restaurantTimezone) {
      throw Error('calling isShiftDayWaitlistAvailableForCurrentPax before initialization')
    }
    if (
      !dailyAvailability.shifts ||
      !dailyAvailability.shifts.length ||
      dailyAvailability.date < formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: this.state.restaurantTimezone })
    ) {
      return false
    }

    for (const shift of dailyAvailability.shifts) {
      for (const slot of shift.shift_slots) {
        if (
          (slot.waitlist_possible_guests || []).includes(this.state.wish.pax) &&
          !slot.closed &&
          this.isShiftWaitlistAvailableWithOffers(shift)
        ) {
          return true
        }
      }
    }
    return false
  })

  isSummaryDayAvailableForCurrentPax = computedFn((day) => {
    if (!this.state.restaurantTimezone) {
      throw Error('calling isSummaryDayAvailableForCurrentPax before initialization')
    }
    if (
      !day.shifts ||
      !day.shifts.length ||
      day.date < formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: this.state.restaurantTimezone })
    ) {
      return 'closed'
    }

    return day.shifts.some((shift) => shift.possible_guests.includes(this.state.wish.pax))
  })

  isSummaryDayWaitlistAvailableForCurrentPax = computedFn((day) => {
    if (!this.state.restaurantTimezone) {
      throw Error('calling isSummaryDayWaitlistAvailableForCurrentPax before initialization')
    }
    if (
      !day.shifts ||
      !day.shifts.length ||
      day.date < formatToTimeZone(new Date(), 'YYYY-MM-DD', { timeZone: this.state.restaurantTimezone })
    ) {
      return false
    }

    return day.shifts.some((shift) => shift.waitlist_possible_guests.includes(this.state.wish.pax))
  })

  @action
  patchOptin: Actions['patchOptin'] = (type, value, customerSheetId) =>
    api.put(
      `patchOptin?restaurantId=${this.state.restaurantId}&customerSheetId=${
        customerSheetId ?? this.state.createdCustomerSheet.id
      }`,
      {
        type,
        value
      }
    )

  @action
  setIsCollapsed: Actions['setIsCollapsed'] = (value) => {
    this.state.isCollapsed = value
  }

  @action
  resetIsCollapsed: Actions['resetIsCollapsed'] = () => {
    this.state.isCollapsed = this.state.showCollapsed
    Router.push({ pathname: '/results', query: Router.query })
  }

  @action
  addOffer: Actions['addOffer'] = (offer_id) => {
    const { wish, selectedSlot, availableOffersFromWish } = this.state
    const wishOffer = wish.offers.find((o) => o.offer_id === offer_id)

    const slotOffer = selectedSlot?.offers.find((o) => o.id === offer_id)

    if (!slotOffer) {
      throw new Error('unsupported use of addOffer method')
    }

    if (slotOffer.config.is_same_for_all) {
      const otherOffersInCartWithLimitedToPax = wish.offers.filter(
        (o) =>
          o.offer_id !== offer_id && availableOffersFromWish.find((a) => a.id === o.offer_id)?.config?.is_limited_to_pax
      )
      otherOffersInCartWithLimitedToPax.forEach((o) => this.removeOffer(o.offer_id))
      wish.offers.push({ offer_id: slotOffer.id, count: wish.pax })
    } else {
      if (wishOffer) {
        wishOffer.count = Math.min(wishOffer.count + 1, slotOffer.stock)
      } else {
        wish.offers.push({ offer_id: slotOffer.id, count: 1 })
      }
    }
  }

  @action
  removeOffer: Actions['removeOffer'] = (offer_id) => {
    const wish = this.state.wish
    const offer = wish.offers.find((o) => o.offer_id === offer_id)
    if (offer) {
      wish.offers.remove(offer)
    }
  }

  @action updateOfferSelectionWithWishData = () => {
    const { wish, selectedShift, selectedSlot, getFullOfferOrNull } = this.state

    if (!selectedShift || !selectedSlot) {
      throw new Error('No shift or slot selected')
    }

    wish.offers.forEach((wishOffer) => {
      const offer = getFullOfferOrNull(wishOffer)
      if (offer?.config.is_same_for_all) {
        wishOffer.count = wish.pax
      }
    })
    const offersOrNull = wish.offers.map((wishOffer) => getFullOfferOrNull(wishOffer))
    const offers = offersOrNull.filter(isNotNull)
    const someOfferIsNull = offersOrNull.length !== offers.length

    if (
      someOfferIsNull ||
      !this.state.isOffersSetValidWithShiftSlotPaxAndRoom(offers, selectedShift, selectedSlot, wish.pax, wish.room_id)
    ) {
      wish.offers.clear()
      this.state.offerSelectionHasBeenCleared = true
    }
  }

  @action
  resetOfferSelectionHasBeenCleared: () => void = () => {
    this.state.offerSelectionHasBeenCleared = false
  }

  @action
  validateVoucherCode = async (inputCode: string): Promise<void> => {
    const { voucherCodes, restaurantId } = this.state
    voucherCodes.error = undefined
    voucherCodes.errorContext = undefined
    try {
      const hasAlreadyExistingVoucherCode = voucherCodes.validatedVoucherCodes.length
      if (hasAlreadyExistingVoucherCode) {
        throw new Error('voucher.code.input.error.already-allowed')
      }

      const {
        data: { voucherCode: code, value: amount, name, type }
      } = await api.post<ValidateVoucherResponse, { voucherCode: string }>(
        `validateVoucher`,
        { voucherCode: inputCode },
        { params: { restaurantId } }
      )

      const voucherCode: ValidatedVoucherCode = { code, type, amount }
      if (name) {
        voucherCode.name = name
      }
      voucherCodes.validatedVoucherCodes = [voucherCode]
    } catch (error) {
      voucherCodes.errorContext = {
        valid_until: error.response?.data?.valid_until
      }
      if (error.response?.data?.reason) {
        voucherCodes.error = error.response.data.reason
      } else if (error.message) {
        voucherCodes.error = error.message
      } else {
        voucherCodes.error = 'voucher.code.input.error.not-valid'
      }
    }
  }
}

export default AppStore
