/* WARNING: these types have been manually inferred from their
 * usage in bookings project code, and might note be complete or 100% accurate.
 * in a future iteration, most of them could be autogenerated from an API documentation.
 */
import { NextRouter } from 'next/router'
import { NextPage } from 'next/types'
import { ReactElement, ReactNode } from 'react'

import { ErrorOrResponseData } from '@/utils/errorMessageHandler'
import { Stores } from '@/utils/StoresContext'

export namespace Bookings {
  export interface Capacity {
    min: number
    max: number
    total_per_slot: number
    turn_times: Record<string, number>
    show_turn_times: boolean
  }

  export interface WishOffer {
    offer_id: number
    count: number
  }

  export interface SlotOffer {
    id: number
    has_prepayment: boolean
    capacity: {
      min: number | null
      max: number | null
      total_per_slot: number | null
    }
    config: {
      is_limited_to_pax: boolean
      is_same_for_all: boolean
      min_pax_available: number
      max_pax_available: number | null
    }
    stock: number
  }
  export interface Room {
    id: number
    name: string
    name_translations: TranslatableValues
  }

  export type ShiftOffer = SlotOffer & {
    stock_id: number
    name: string
    name_translations: TranslatableValues
    charge_per_guests: null | number
    count: number
    picture_url?: string
    description?: TranslatableValues
    has_prepayment: boolean
    is_unlimited: boolean
    total: number
    has_specific_rooms: boolean
    rooms: Room['id'][]
  }

  export type SelectedOffer = ShiftOffer & SlotOffer

  export interface Slot {
    id: number
    slot_name: string
    name: string
    closed: boolean
    offers: SlotOffer[]
    available_rooms?: Record<number, number[]>
    possible_guests: number[]
    marked_as_full?: boolean
    waitlist_possible_guests: number[]
    capacity?: Capacity
    disabled?: boolean
  }

  export interface ShiftChargeParam {
    is_web_booking_askable?: boolean
    min_guests?: number
    charge_per_guests?: number
  }

  export interface Shift {
    confirmation?: {
      is_auto: boolean
      is_auto_until: number
    }
    comment?: TranslatableValues
    id: number
    shift_slots: Slot[]
    open: string
    close: string
    name: string
    name_translations: TranslatableValues
    cancelation_param?: {
      enduser_cancelable_reference?: 'shift' | string
      enduser_cancelable_before: number
    }
    offers?: ShiftOffer[]
    bookable_rooms?: number[]
    charge_param?: ShiftChargeParam
    prepayment_param?: ShiftChargeParam
    marked_as_full?: boolean
    is_offer_required: boolean
    offer_required_from_pax?: number
    total?: number
    capacity?: Capacity
    bookable_from?: string
    bookable_to?: string
  }

  export interface SummaryShift
    extends Pick<Shift, 'id' | 'name' | 'name_translations' | 'bookable_from' | 'bookable_to'> {
    possible_guests: number[]
    waitlist_possible_guests: number[]
    closed: boolean
  }

  export interface RestaurantInfo {
    name: string
    email: string
    website: string
    cancelable_before: number
    mediator_contact: string
    company: string
    city: string
    capital: string
    rcs: string
    tva: string
    headquarters: string
  }

  export interface Product {
    price: number
    quantity: number
    id: number
    sku: string
    name: string
    description: string
    expired_after: number
  }

  export interface Optin {
    type: 'market_mail' | 'market_sms' | 'review_mail' | 'review_sms'
    value: number | boolean
  }

  export interface CustomField {
    slug: string
    displayType: 'text' | 'select' | 'radio' | 'checkbox'
    title: string
    options: { value: string; text: string }[]
  }

  export interface Wish {
    day: string
    pax: number
    slot: string | undefined
    waiting_list: boolean
    offers: WishOffer[]
    room_id: number | null
    lang?: string
  }

  export interface PreviousWish extends Partial<Omit<Wish, 'offers'>> {
    offers?: WishOffer[]
  }

  export declare type UnmodifiableReasons =
    | 'charge'
    | 'no_longer_time'
    | 'out_of_shift'
    | 'shift_feature_disabled'
    | 'unprocessable_phase'

  export interface BookingDetails {
    is_modifiable_by_enduser: boolean
    unmodifiable_by_enduser_reasons: UnmodifiableReasons[]
    id: number
    uuid: string
    type: string
    partner_id: string
    restaurant_id: number
    resto_id: number
    resto_name: string
    day: string
    time: string
    nb_guests: number
    slots_count: unknown
    civility: string | 'mrs' | 'mr' | 'mx'
    firstname: string
    lastname: string
    country: string
    phone_number: string
    email: string
    comment: string
    lang: AvailableLanguages
    status: string
    shift_slot_id: number
    custom_field: Record<string, unknown>
    booking_offers: unknown[]
    shift_date: string
    wish: {
      booking_id: number
      booking_room_id: number | null
    }
    customersheet: {
      optins: Optin[]
    }
    customer_sheet_id: number
    // incomplete
  }
}

const ALL_WISH_FILTERS = ['area', 'slot', 'pax', 'calendar'] as const
export type WishFilter = (typeof ALL_WISH_FILTERS)[number]
export function isWishFilter(value: string): value is WishFilter {
  return ALL_WISH_FILTERS.includes(value as WishFilter)
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (
    page: ReactElement,
    additionalProps: {
      stores: Stores
      router: NextRouter
      t: (key: string) => string
      // goTo: (path: string) => void
    }
  ) => ReactNode
}

// - "arrangement": contains experiences
// - "value": contains discounts
export type VoucherType = 'arrangement' | 'value'

interface AbstractVoucherCode {
  code: string
  name?: string
}

export interface ValidatedVoucherCode extends AbstractVoucherCode {
  amount: number
  type: VoucherType
  error?: string
}

export interface BookingVoucherCode extends AbstractVoucherCode {
  originalAmount: number
  usedAmount: number
}

export type VoucherCode = BookingVoucherCode | ValidatedVoucherCode

export const isValidatedVoucherCode = (voucherCode: VoucherCode): voucherCode is ValidatedVoucherCode =>
  'type' in voucherCode

export const isBookingVoucherCode = (voucherCode: VoucherCode): voucherCode is BookingVoucherCode =>
  !isValidatedVoucherCode(voucherCode)

interface AbstractVoucherParam {
  id: number
  restaurant_payment_provider_id: number | null
}

interface V1VoucherParam extends AbstractVoucherParam {
  version: 'v1'
  tablebooker_shop_id: null
}

interface TableBookerVoucherParam extends AbstractVoucherParam {
  version: 'tablebooker'
  tablebooker_shop_id: string
}

export type VoucherParam = V1VoucherParam | TableBookerVoucherParam

export const isTablebookerVoucherParam = (voucherParam?: VoucherParam): voucherParam is TableBookerVoucherParam =>
  voucherParam?.version === 'tablebooker' && !!voucherParam.tablebooker_shop_id

export interface ValidateVoucherResponse {
  is_valid: true
  reason: string | null
  voucherCode: string
  type: VoucherType
  name: string | null
  value: number
  valid_until: string | null
}

export interface PersonalFormData {
  firstname: string
  lastname: string
  civility: string
  phone: string
  email: string
  optins: Bookings.Optin[]
  country: string
  lang: string
}

interface Charge {
  quotation_id: number | null | undefined
  payment_intent_id?: number
  payment_method_id?: number
  setup_intent_id?: string | null
  successUrl?: string
}

export interface CustomerSheet {
  relatedBookingId: number | null
  id: number | null
  optins: Bookings.Optin[] | null
  relatedBookingStatus?: string
}

export interface VoucherCodeApi {
  name: string
  code: string
  original_amount: number
  used_amount: number
}

export interface CreateBookingPayload extends Omit<PersonalFormData, 'phone' | 'optins'> {
  charge?: Charge
  comment: string
  country: string
  custom_field?: Record<string, string>
  customersheet?: PersonalFormData
  day: string
  icid?: string
  nb_guests: number
  offers: Bookings.WishOffer[]
  partner_id: string
  phase?: 'waiting_payment' | 'waiting_imprint' | 'waiting_list'
  phone_number: string
  prescriber_id?: string
  source_restaurant_id?: number
  status?: string
  time: string | undefined
  type: string | null
  voucher_codes?: Pick<VoucherCodeApi, 'code'>[]
  wish?: { booking_room_id: number | null }
}

export interface CreateBookingResponse extends ErrorOrResponseData {
  id: number
  uuid: string
  sha256: string
  requires_action: boolean
  customersheet: CustomerSheet
  status: string
  charge?: { stripe_client_secret: string }
  expires_at?: number
  voucher_codes?: VoucherCodeApi[]
}
